import {
  View,
  Text,
  Image,
  Platform,
  Animated,
  Dimensions,
  ScrollView,
  SafeAreaView,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Progress from "react-native-progress";
import { showMessage } from "react-native-flash-message";

//Function
import fields from "../../Utility/Fields";
import { setLoader } from "../../Reducer/tia";
import { MAIN_COLOR } from "../../Utility/Colors";
import { user as setUser } from "../../Reducer/user";
import { MAIN_FONT } from "../../Utility/Fonts/font";
import { getDateTime, getTime } from "../../Zoho/Tasks";
import { getProperty, getMarket } from "../../Clickup/api";
import { getContactByEmail } from "../../Clickup/api";

//Component
import TaskDetails from "../TaskDetails/indexv2";
import MainLayout from "../../Layout/Main";
import Presentation from "../Presentation/index.v2";
import TaskItem from "./components/TaskItem";
import PropertyDetails from "../PropertyDetails";
import PropertyDetail from "../PropertyDetails/index.v2";
import OpportunityItem from "../../components/OpportunityItem";
import Update from "../TaskDetails/components/Update";
import ButtonStatus from "./components/ButtonStatus";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deal: props.deal,
      checklists: props?.checklists ?? [],
      clickUpStatusList: props?.clickUpStatusList ?? [],
      notificationIndex: props.notificationIndex,
      stage: props.deal?.status?.status?.toUpperCase(),
      dimensions: {
        window: Dimensions.get("window"),
        screen: Dimensions.get("screen"),
      },
      templateKeys: [],
      notifications: {},
      template: {},
      progress: 0,
      index: 0,
      task: null,
      contacts: [],
    };

    this.scrollViewRef = React.createRef();
  }

  componentWillUnmount() {
    this.dimensionsSubscription?.remove();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.index !== this.state.index) {
      this.scrollViewRef.current?.scrollTo({ y: 0, animated: false });
    }
  }

  getVariables(str, name) {
    let vars = str.match(/\*(.*?)\*/g);
    let arr = {};

    if (vars) {
      vars.map((item) => {
        let item1 = item.replace(/\*/g, "");

        if (item1.includes("[")) {
          let vars2 = item1.match(/\[(.*?)\]/g);

          vars2.map((item2, index2) => {
            item1 = item1.replace(item2, "");

            item2 = item2.replace("[", "");
            item2 = item2.replace("]", "");

            if (this.state.deal[item1]) {
              let obj = this.state.deal[item1][0];
              if (obj) {
                str = str.replace(item, obj[item2]);
              } else {
                str = str.replace(item, this.state.deal[item1][item2]);
              }
              this.setState({
                vars: {
                  ...this.state.vars,
                  [item]: this.state.deal[item1][item2],
                },
              });
            }
          });
        } else {
          if (
            name === "Videos" ||
            name === "Files" ||
            name === "Link" ||
            name === "FACEBOOK REVIEW" ||
            name === "GOOGLE REVIEW" ||
            name === "REVIEW"
          ) {
            if (this.state.deal[item1]) {
              arr[item1] = this.state.deal[item1];
            }
          } else {
            if (
              item1 === "B&P Unconditional Date" ||
              item1 === "Finance Unconditional Date" ||
              item1 === "Settlement Date"
            ) {
              str = str.replace(
                item,
                moment(this.state.deal[item1] * 1).format("DD/MM/YYYY")
              );
            } else {
              str = str.replace(item, this.state.deal[item1]);
            }
          }

          this.setState({
            vars: { ...this.state.vars, [item]: this.state.deal[item1] },
          });
        }
      });
    }

    if (
      name === "Videos" ||
      name === "Files" ||
      name === "Link" ||
      name === "FACEBOOK REVIEW" ||
      name === "GOOGLE REVIEW" ||
      name === "REVIEW"
    ) {
      return arr;
    } else {
      return str;
    }
  }

  checkMarket() {
    if (this.state.deal["Market"][0]) {
      let market = this.state.deal["Market"][0];

      getMarket(market.id).then((result) => {
        if (!result.err) {
          var object = result.custom_fields.reduce(
            (obj, item) => Object.assign(obj, { [item.name]: item.value }),
            {}
          );

          result["custom_fields"] = null;
          this.setState(
            {
              deal: {
                ...this.state.deal,
                ["Market Preso URL"]: object["Market Preso URL"],
                ["Market Preso Video URL"]: object["Market Preso Video URL"],
                ["Market Data"]: object["Market Data"].map(
                  (item, index) => item.url
                ),
              },
            },
            () => {
              if (this.state.deal["Proposed Property"]) {
                this.checkProperty();
              } else {
                this.populate();
              }
            }
          );
        } else {
          if (this.state.deal["Proposed Property"]) {
            this.checkProperty();
          } else {
            this.populate();
          }
        }
      });
    } else {
      this.populate();
    }
  }

  checkProperty() {
    if (this.state.deal["Proposed Property"][0]) {
      let deal = this.state.deal["Proposed Property"][0];

      getProperty(deal.id).then((result) => {
        if (!result.err) {
          var object = result.custom_fields.reduce(
            (obj, item) => Object.assign(obj, { [item.name]: item.value }),
            {}
          );

          result["custom_fields"] = null;
          this.setState(
            {
              deal: {
                ...this.state.deal,
                ["Rent Appraisal Link"]: object["Rent Appraisal Link"],
                ["Walk Through Videos"]: object["Walk Through Videos"],
                ["Pre Settlement Inspection Videos"]:
                  object["Pre Settlement Inspection Videos"],
                ["Pre Settlement Inspec. Comments"]:
                  object["Pre Settlement Inspec. Comments"],
                ["PROPERTY STREET ADDRESS"]: object["PROPERTY STREET ADDRESS"],
                ["Building Report"]: object["Building Report"],
                ["Pest Report"]: object["Pest Report"],
                ["Property Condition Notes"]:
                  object["Property Condition Notes"],
              },
            },
            () => {
              this.populate();
            }
          );
        } else {
          this.populate();
        }
      });
    } else {
      this.populate();
    }
  }

  populate() {
    this.setState({ refreshing: true });
    let template = {};
    const deal = this.state.deal;

    const _contacts = [];
    Object.keys(this.props.TIA.template).map(async (key, index) => {
      let item = this.props.TIA.template[key];

      if (item) {
        Object.keys(item).map((key2) => {
          let item2 = item[key2];
          if (item2) {
            let r = this.getVariables(item2, key2);
            template = {
              ...template,
              [key.toUpperCase()]: {
                ...template[key.toUpperCase()],
                [key2]: r,
              },
            };
          }
        });

        _contacts.push({
          appTask: item["App Task & Details Name"],
          contacts: [],
        });

        if (item["App Task & Details Name"] === "Profile Review") {
          if (
            deal["SOLICITOR'S NAME"] &&
            deal["SOLICITOR'S PHONE"] &&
            deal["Solicitor's Email"]
          ) {
            _contacts[index].contacts.push({
              name: deal["SOLICITOR'S NAME"],
              phone: deal["SOLICITOR'S PHONE"],
              email: deal["Solicitor's Email"],
              type: "Legal Partner",
            });
          }

          if (
            deal["MORTGAGE BROKER"] &&
            deal["MORTGAGE BROKER EMAIL"] &&
            deal["MORTGAGE BROKER PHONE"]
          ) {
            _contacts[index].contacts.push({
              name: deal["MORTGAGE BROKER"],
              email: deal["MORTGAGE BROKER EMAIL"],
              phone: deal["MORTGAGE BROKER PHONE"],
              type: "Financial Partner",
            });
          }

          await getContactByEmail("taliesha@theinvestorsagency.com.au").then(
            (result) => {
              const tasks = result?.tasks;
              if (tasks.length) {
                const custom_fields = tasks[0].custom_fields;
                const adminStrationPhone = custom_fields.find(
                  (field) => field.name === "Phone"
                )?.value;

                const avatar = custom_fields.find(
                  (field) => field.name === "Contact photo"
                )?.value;
                const adminStrationAvatar = avatar?.length
                  ? avatar[0]?.url
                  : null;
                if (adminStrationPhone) {
                  _contacts[index].contacts.push({
                    type: "Administration Manager",
                    name: "Taliesha Vukic",
                    phone: adminStrationPhone,
                    email: "taliesha@theinvestorsagency.com.au",
                    avatar: adminStrationAvatar,
                  });
                }
              }
            }
          );
        }

        if (item["App Task & Details Name"] === "Search Process") {
          if (deal?.assignees?.[0]?.email) {
            await getContactByEmail(deal.assignees?.[0]?.email).then(
              (result) => {
                const tasks = result?.tasks;
                if (tasks.length) {
                  const custom_fields = tasks[0].custom_fields;
                  const buyerAgentName = tasks[0].name;
                  const buyerAgentEmail = custom_fields.find(
                    (field) => field.name === "Email"
                  )?.value;
                  const buyerAgentPhone = custom_fields.find(
                    (field) => field.name === "Phone"
                  )?.value;

                  const avatar = custom_fields.find(
                    (field) => field.name === "Contact photo"
                  )?.value;
                  const buyerAgentAvatar = avatar?.length
                    ? avatar[0]?.url
                    : null;
                  _contacts[index].contacts.push({
                    name: buyerAgentName,
                    email: buyerAgentEmail,
                    phone: buyerAgentPhone,
                    avatar: buyerAgentAvatar,
                    type: "Associate Buyers Agent",
                  });
                }
              }
            );
          }

          if (deal["OPPORTUNITY OWNER"]?.[0]?.email) {
            await getContactByEmail(deal["OPPORTUNITY OWNER"]?.[0]?.email).then(
              (result) => {
                const tasks = result?.tasks;
                if (tasks.length) {
                  const custom_fields = tasks[0].custom_fields;
                  const headBuyerAgentName = tasks[0].name;
                  const headBuyerAgentEmail = custom_fields.find(
                    (field) => field.name === "Email"
                  )?.value;
                  const headBuyerAgentPhone = custom_fields.find(
                    (field) => field.name === "Phone"
                  )?.value;

                  const avatar = custom_fields.find(
                    (field) => field.name === "Contact photo"
                  )?.value;
                  const headBuyerAgentAvatar = avatar?.length
                    ? avatar[0]?.url
                    : null;
                  _contacts[index].contacts.push({
                    name: headBuyerAgentName,
                    email: headBuyerAgentEmail,
                    phone: headBuyerAgentPhone,
                    avatar: headBuyerAgentAvatar,
                    type: "Head Buyers Agent",
                  });
                }
              }
            );
          }
        }

        if (
          item["App Task & Details Name"] ===
          "Contract, Building & Pest and Property Manager"
        ) {
          if (
            deal["B&P Inspector"] &&
            deal["B&P Inspector Email"] &&
            deal["B&P Inspector Phone"]
          ) {
            _contacts[index].contacts.push({
              name: deal["B&P Inspector"],
              email: deal["B&P Inspector Email"],
              phone: deal["B&P Inspector Phone"],
              type: "Building and Pest Inspector",
            });
          }

          if (
            deal["Property Manager"] &&
            deal["Property Manager Email"] &&
            deal["Property Manager Phone"]
          ) {
            _contacts[index].contacts.push({
              name: deal["Property Manager"],
              email: deal["Property Manager Email"],
              phone: deal["Property Manager Phone"],
              type: "Property Manager",
            });
          }
        }

        if (item["App Task & Details Name"] === "Settlement") {
          await getContactByEmail("ayden.hassan@mcgqs.com.au").then(
            (result) => {
              const tasks = result?.tasks;
              if (tasks?.length) {
                const custom_fields = tasks[0].custom_fields;
                const quantitySurveyorPhone = custom_fields.find(
                  (field) => field.name === "Phone"
                )?.value;

                _contacts[index].contacts.push({
                  type: "Quantity Surveyor",
                  name: "Ayden Hassan",
                  phone: quantitySurveyorPhone,
                  email: "ayden.hassan@mcgqs.com.au",
                });
              }
            }
          );
        }
      }

      let arr = Object.keys(template);
      let stageIndex = arr.indexOf(this.state.stage);
      let progress;
      if (stageIndex < 1) {
        progress = 0;
      } else {
        progress = (stageIndex * 100) / (arr.length - 1);
      }
      this.props.setLoader(false);
      this.setState(
        {
          template: template,
          templateKeys: arr,
          stageIndex: stageIndex - 1,
          progress: progress,
          refreshing: false,
          notifications: this.props.TIA.notificationObjects[this.state.deal.id]
            ? this.props.TIA.notificationObjects[this.state.deal.id]
            : {},
        },
        () => {}
      );
    });

    this.setState({ contacts: _contacts });
  }

  async refresh() {
    if (this.state.deal?.["Market"]) {
      this.checkMarket();
    } else if (this.state.deal["Proposed Property"]) {
      this.checkProperty();
    } else {
      this.populate();
    }
  }

  async componentDidMount() {
    this.props.setLoader(true);
    this.refresh();
    this.dimensionsSubscription = Dimensions.addEventListener(
      "change",
      this.onChange
    );
  }

  render() {
    const {
      task,
      deal,
      index,
      template,
      stageIndex,
      checklists,
      templateKeys,
      notifications,
      contacts,
      notificationIndex,
    } = this.state;

    const addMin = (time) => {
      var currentDateObj = new Date(time);
      var numberOfMlSeconds = currentDateObj.getTime();
      var addMlSeconds = 1 * 60 * 1000;
      var newDateObj = new Date(numberOfMlSeconds + addMlSeconds);

      return getDateTime(newDateObj);
    };

    return (
      <MainLayout
        childrenHeader={
          <View style={[styles.rowCenterBetween, { height: "100%" }]}>
            <View style={styles.rowCenterBetween}>
              <TouchableOpacity
                onPress={() => this.props.setStateScreen("Home")}
              >
                <Image
                  style={{ width: 30, height: 30 }}
                  source={require("../../assets/home/back.svg")}
                />
              </TouchableOpacity>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text style={styles.headerTitle}>Project Detail </Text>
                <Image
                  style={{
                    width: 30,
                    height: 30,
                    transform: [{ rotate: "180deg" }],
                  }}
                  source={require("../../assets/home/back.svg")}
                />
                <Text style={[styles.headerTitle, { color: "#D3B100" }]}>
                  {task?.task["App Task & Details Name"]}
                </Text>
              </View>
            </View>
            <ButtonStatus
              status={task?.status}
              style={{ height: 30, paddingHorizontal: 12, paddingVertical: 6 }}
            />
          </View>
        }
        childrenMenu={
          <View style={styles.menuContainer}>
            <OpportunityItem
              item={deal}
              style={{ width: "100%", height: 272 }}
            />
            <View style={{ display: "flex", gap: 12 }}>
              <Text style={styles.menuTitle}>Project Task</Text>
              {templateKeys.length &&
                templateKeys.map((key, index) => {
                  if (index === Object.keys(template).length - 1) return null;

                  let status;
                  let time = null;
                  let item = template[key];
                  let curNotification = notifications[templateKeys[index]];
                  let nextNotification = notifications[templateKeys[index + 1]];

                  if (nextNotification) {
                    time = nextNotification.dateUpdated.seconds * 1000;
                  }

                  if (index === stageIndex) {
                    status = "Complete";
                  } else if (index < stageIndex) {
                    if (nextNotification) {
                      status = "Complete";
                    } else if (curNotification) {
                      status = "In Progress";
                    } else {
                      status = "Due";
                    }
                  } else if (index === stageIndex + 1) {
                    if (!curNotification) {
                      status = "Due";
                      showMessage({
                        message:
                          "Issue encountered with data please report to Suppport.",
                        type: "info",
                        color: MAIN_COLOR.BACKGROUND_COLOR_MAIN,
                        backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
                      });
                    } else {
                      status = "In Progress";
                    }
                  } else if (index > stageIndex + 1) status = "Due";

                  if (index === 4 && !deal["Walk-through videos uploaded?"]) {
                    status = "Due";
                  }

                  const isComplete = checklists
                    ?.find((check) => check?.task_id === task?.id)
                    ?.items?.some((check) => check?.resolved === false);

                  if (key === "UNCONDITIONAL" && status !== "Due") {
                    if (!isComplete) status = "Complete";
                    else status = "In Progress";
                  }

                  let minutes = 0;
                  if (status == "In Progress") {
                    const msDiff =
                      new Date() -
                      new Date(curNotification?.dateUpdated?.seconds * 1000);

                    const diff = Math.floor(msDiff / (1000 * 60));
                    minutes = diff;
                  }

                  let filesCount = 0;
                  let linksCount = 0;
                  let videosCount = 0;
                  if (status === "Complete") {
                    filesCount = item.Files
                      ? Object.keys(item.Files).length
                      : 0;
                    videosCount = item.Videos
                      ? Object.keys(item.Videos).length
                      : 0;
                    linksCount = item.Link ? Object.keys(item.Link).length : 0;

                    if (videosCount) {
                      if (item.Videos["Walk Through Videos"]) {
                        videosCount =
                          item.Videos["Walk Through Videos"].split("\n").length;
                      } else if (
                        item.Videos["Pre Settlement Inspection Videos"]
                      ) {
                        videosCount =
                          item.Videos["Pre Settlement Inspection Videos"].split(
                            "\n"
                          ).length;
                      }
                    }
                    if (filesCount) {
                      if (item.Files["Market Data"]) {
                        filesCount = item.Files["Market Data"].length;
                      }
                    }
                  }

                  if (index === 0 && !task) {
                    let progressTime;
                    if (status == "In Progress") {
                      progressTime = addMin(
                        curNotification?.dateUpdated?.seconds * 1000
                      );
                    } else {
                      progressTime = addMin(
                        nextNotification?.dateUpdated?.seconds * 1000
                      );
                    }
                    this.setState({
                      index,
                      task: {
                        id: this.state.deal.id,
                        task: item,
                        key,
                        status: status,
                        time: time && time,
                        progressTime: progressTime,
                        filesCount,
                        videosCount,
                        linksCount,
                      },
                    });
                  }

                  if (
                    status === "Complete" &&
                    this.state.notificationIndex === index
                  ) {
                    this.setState({ notificationIndex: null });

                    let progressTime = addMin(
                      nextNotification?.dateUpdated.seconds * 1000
                    );

                    if (index === 3 && time) {
                      if (deal["Proposed Property"]) {
                        this.setState({
                          index,
                          task: {
                            property: deal["Proposed Property"]
                              ? deal["Proposed Property"][0]
                              : null,
                            video: item.Videos,
                            task: item,
                            key,
                            status: status,
                            video: item.Videos,
                            progressTime: progressTime,
                            filesCount,
                            videosCount,
                            linksCount,
                            booking: item["Booking URL"],
                          },
                        });
                      } else {
                        showMessage({
                          message: "No property assigned.",
                          type: "info",
                          color: "#FFF",
                          backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
                        });
                      }
                    } else if (index === 2 && status === "Complete") {
                      this.setState({
                        index,
                        task: {
                          market: deal["Markets"] ? deal["Markets"][0] : null,
                          video: item.Videos,
                          task: item,
                          key,
                          status: status,
                          video: item.Videos,
                          progressTime: progressTime,
                          filesCount,
                          videosCount,
                          linksCount,
                          booking: item["Booking URL"],
                        },
                      });
                    } else {
                      this.setState({
                        index: index,
                        task: {
                          id: this.state.deal.id,
                          task: item,
                          key,
                          status: status,
                          time: time && time,
                          comments:
                            index === 7 &&
                            this.state.deal["Pre Settlement Inspec. Comments"],
                          progressTime: progressTime,
                          filesCount,
                          videosCount,
                          linksCount,
                        },
                      });
                    }
                  }

                  const listContacts = contacts.find(
                    ({ appTask }) => appTask === item["App Task & Details Name"]
                  ).contacts;

                  return (
                    <TaskItem
                      active={this.state.index === index}
                      task={item}
                      key={index}
                      status={status}
                      time={time}
                      contacts={listContacts}
                      prevNotification={curNotification}
                      onPress={() => {
                        let progressTime;
                        if (status == "In Progress") {
                          progressTime = addMin(
                            curNotification?.dateUpdated?.seconds * 1000
                          );
                        } else {
                          progressTime = addMin(
                            nextNotification?.dateUpdated?.seconds * 1000
                          );
                        }

                        if (index === 3 && time) {
                          if (deal["Proposed Property"]) {
                            this.setState({
                              index,
                              task: {
                                property: deal["Proposed Property"]
                                  ? deal["Proposed Property"][0]
                                  : null,
                                video: item.Videos,
                                task: item,
                                key,
                                status: status,
                                video: item.Videos,
                                progressTime: progressTime,
                                filesCount,
                                videosCount,
                                linksCount,
                                booking: item["Booking URL"],
                              },
                            });
                          } else {
                            showMessage({
                              message: "No property assigned.",
                              type: "info",
                              color: "#FFF",
                              backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
                            });
                          }
                        } else if (index === 2 && status === "Complete") {
                          this.setState({
                            index,
                            task: {
                              market: deal["Markets"]
                                ? deal["Markets"][0]
                                : null,
                              video: item.Videos,
                              task: item,
                              key,
                              status: status,
                              video: item.Videos,
                              progressTime: progressTime,
                              filesCount,
                              videosCount,
                              linksCount,
                              booking: item["Booking URL"],
                            },
                          });
                        } else {
                          this.setState({
                            index: index,
                            task: {
                              id: this.state.deal.id,
                              task: item,
                              key,
                              status: status,
                              time: time && getDateTime(time),
                              comments:
                                index === 7 &&
                                this.state.deal[
                                  "Pre Settlement Inspec. Comments"
                                ],
                              progressTime: progressTime,
                              filesCount,
                              videosCount,
                              linksCount,
                            },
                          });
                        }
                      }}
                    />
                  );
                })}
            </View>
          </View>
        }
      >
        <ScrollView ref={this.scrollViewRef} showsVerticalScrollIndicator={false}>
          <View style={styles.content}>
            {task ? (
              index == 2 && task.market ? (
                <Presentation
                  market={task.market}
                  booking={task.booking}
                  assignee={deal.assignees.length ? deal.assignees[0] : null}
                />
              ) : index == 3 && task.property ? (
                <PropertyDetail
                  property={deal["Proposed Property"][0]}
                  video={task.video}
                  booking={task.booking}
                  assignee={deal.assignees.length ? deal.assignees[0] : null}
                  taskId={deal.id}
                />
              ) : (
                <TaskDetails
                  clickUpStatusList={this.state.clickUpStatusList}
                  checklists={this.state.checklists}
                  comments={task.comments}
                  linkVideo={task["Link Video"]}
                  id={task.id}
                  task={task.task}
                  key={task.key}
                  status={task.status}
                  time={task.time}
                  progressTime={task.progressTime}
                  filesCount={task.filesCount}
                  videosCount={task.videosCount}
                  linksCount={task.linksCount}
                  propertyConditionNotes={
                    deal["Property Condition Notes"] || ""
                  }
                  solicitorName={deal["SOLICITOR'S NAME"] || null}
                  solicitorPhone={deal["SOLICITOR'S PHONE"] || null}
                  solicitorEmail={deal["Solicitor's Email"] || null}
                  brokerName={deal["MORTGAGE BROKER"] || null}
                  brokerEmail={deal["MORTGAGE BROKER EMAIL"] || null}
                  brokerPhone={deal["MORTGAGE BROKER PHONE"] || null}
                  oppotunityAssigneeEmail={
                    deal?.assignees?.length ? deal?.assignees[0]?.email : null
                  }
                  oppotunityHeadBuyerAgentEmail={
                    deal["OPPORTUNITY OWNER"]?.length
                      ? deal["OPPORTUNITY OWNER"][0]?.email
                      : null
                  }
                  bpInspectorName={deal["B&P Inspector"] || null}
                  bpInspectorEmail={deal["B&P Inspector Email"] || null}
                  bpInspectorPhone={deal["B&P Inspector Phone"] || null}
                  propertyManagerName={deal["Property Manager"] || null}
                  propertyManagerEmail={deal["Property Manager Email"] || null}
                  propertyManagerPhone={deal["Property Manager Phone"] || null}
                />
              )
            ) : null}
          </View>
        </ScrollView>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  menuContainer: {
    display: "flex",
    gap: 22,
  },

  menuTitle: {
    fontWeight: 600,
    fontSize: 16,
    lightHeight: 24,
    color: MAIN_COLOR.BACKGROUND_COLOR,
    fontFamily: MAIN_FONT.semi,
  },

  rowCenterBetween: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 16,
  },

  headerTitle: {
    fontSize: 20,
    fontWeight: 600,
    lightHeight: 30,
    color: MAIN_COLOR.BACKGROUND_COLOR,
    fontFamily: MAIN_FONT.semi,
  },

  content: {
    display: "flex",
    gap: 20,
    paddingVertical: 22,
    paddingHorizontal: 32,
  },

  contentTitle: {
    fontSize: 20,
    fontWeight: 600,
    lightHeight: 30,
    color: MAIN_COLOR.BACKGROUND_COLOR,
    fontFamily: MAIN_FONT.regular,
  },
});

function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    TIA: state.TIA,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setUser,
      setLoader,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
