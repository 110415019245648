import React, { memo, useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import Carousel from "react-native-reanimated-carousel";
import { MAIN_COLORS } from "../Utility/Colors";
import { TouchableOpacity } from "react-native";

function CarouselComp({
  data = [],
  loop = false,
  width,
  height,
  paginate = false,
  vertical = false,
  renderItem = () => <></>,
  styleContainer,
  stylePaginateDot,
  stylePaginateDotActive,
  stylePaginateContainer,
}) {
  const ref = React.useRef(null);

  const [paginateHeight, setPaginateHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [progress, setProgress] = useState(0);

  const itemOfPage = Math.round(containerWidth / width) || 1;
  const totalPages = Math.ceil(data?.length / itemOfPage) || 1;

  const onPressPagination = (index) => {
    setProgress(index);
    ref.current?.scrollTo({ index: index * itemOfPage, animated: true });
  };

  useEffect(() => {
    if (progress > totalPages) {
      setProgress(0);
      ref.current?.scrollTo({ index: 0, animated: true });
    }
  }, [containerWidth]);

  return (
    <View
      id="carousel-component"
      dataSet={{ kind: "custom-animations", name: "multiple" }}
      style={{
        height:
          height +
          paginateHeight +
          (styles.paginateContainer?.marginTop ||
            stylePaginateContainer?.marginTop),
      }}
      onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}
    >
      <Carousel
        ref={ref}
        loop={loop}
        width={width}
        height={height}
        snapEnabled={true}
        vertical={vertical}
        style={[styles.container, styleContainer]}
        data={data}
        onSnapToItem={(index) => setProgress(Math.floor(index / itemOfPage))}
        renderItem={renderItem}
      />

      {paginate && (
        <View
          style={[styles.paginateContainer, stylePaginateContainer]}
          onLayout={(e) => setPaginateHeight(e.nativeEvent.layout.height)}
        >
          {Array(totalPages)
            .fill(0)
            .map((item, index) => {
              return (
                <TouchableOpacity
                  key={index}
                  activeOpacity={1}
                  onPress={() => onPressPagination(index)}
                >
                  <View
                    style={[
                      stylePaginateDot,
                      progress === index
                        ? stylePaginateDotActive
                          ? stylePaginateDotActive
                          : styles.paginateDotActive
                        : stylePaginateDot
                        ? stylePaginateDot
                        : styles.paginateDot,
                    ]}
                  />
                </TouchableOpacity>
              );
            })}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "transparent",
  },

  paginateContainer: {
    flexDirection: "row",
    gap: 4,
    marginTop: 15,
    alignItems: "center",
    justifyContent: "center",
  },

  paginateDot: {
    width: 4,
    height: 4,
    borderRadius: 16,
    backgroundColor: MAIN_COLORS.BACKGROUND_WHITE,
  },

  paginateDotActive: {
    width: 16,
    height: 4,
    borderRadius: 16,
    backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
  },
});

export default memo(CarouselComp);
