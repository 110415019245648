import moment from "moment";
import React, { memo } from "react";
import { View, Text, Image, StyleSheet } from "react-native";

//Functions
import { MAIN_COLORS } from "../Utility/Colors";
import { FONT_SIZE, MAIN_FONT } from "../Utility/Fonts/font";

const TimeComp = ({ date, due = false }) => {
  let time, formattedDate;

  if (typeof date === "string" && date.includes(" - ")) {
    const [timeString, dateString] = date.split(" - ");
    time = timeString;
    formattedDate = dateString;
  } else {
    time = moment(date).format("h:mm A");
    formattedDate = moment(date).format("DD/MM/YYYY");
  }

  return (
    <View style={styles.rowCenter}>
      <View style={[styles.rowCenter, { gap: 4 }]}>
        {due ? (
          <Text style={styles.text}>Due</Text>
        ) : (
          <>
            <Image
              style={styles.icon}
              source={require("../assets/home/clock.svg")}
            />
            <Text style={styles.text}>{time}</Text>
          </>
        )}
      </View>
      <View style={styles.divider} />
      <View style={[styles.rowCenter, { gap: 4 }]}>
        <Image
          style={styles.icon}
          source={require("../assets/home/calendar.svg")}
        />
        {due ? (
          <Text style={styles.text}>
            {moment().format("DD/MM/YYYY")}
          </Text>
        ) : (
          <Text style={styles.text}>{formattedDate}</Text>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  rowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 6,
  },
  divider: {
    width: 2,
    height: 12,
    borderRadius: 6,
    backgroundColor: MAIN_COLORS.TEXT_GRAY,
  },

  icon: {
    width: 18,
    height: 18,
  },

  text: {
    fontSize: FONT_SIZE.small,
    lineHeight: 16,
    fontWeight: 500,
    color: MAIN_COLORS.TEXT_GRAY,
    fontFamily: MAIN_FONT.regular,
  },
});

export default memo(TimeComp);
