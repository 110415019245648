import React from "react";
import {
  Dimensions,
  Keyboard,
  Platform,
  RefreshControl,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
  Image,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { NavigationEvents } from "react-navigation";
import "@firebase/firestore";
import firebase from "firebase";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { user as setUser } from "../../Reducer/user";
import { setNotifications, setNotificationsObject } from "../../Reducer/tia";
import { MAIN_COLOR, MAIN_COLORS } from "../../Utility/Colors";
import LOADER from "../../Utility/ComponentCard/Loader";
import { MAIN_FONT } from "../../Utility/Fonts/font";
import tasks from "../../Zoho/Tasks";
import styles from "./stylev2";
import fields from "../../Utility/Fields";

import MainLayout from "../../Layout/Main";
import { stylesHome } from "../Home/components/index.v2";
import Time from "../../components/Time";
import CalendarCard from "../../Utility/ComponentCard/CalendarCard";
import EmptyContent from "../../components/EmptyContent";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      today: new Date(),
      week: [],
      notifications: [],
      raw: [],
      tasks: [],
      email: null,
      refreshing: false,
      markedDates: this.props.markedDates,
    };
  }

  componentWillUnmount() { }

  componentDidUpdate() { }

  goToTaskDetails(statusName, id, time, status) {
    if (statusName == "MARKET UPDATES") {
      this.props.navigation.navigate("Portfolio");
      return null;
    }

    let deal = this.props.TIA.opportunities.find(
      (item, index) => item.id == id
    );

    if (deal) {
      let taskArr = tasks(deal);

      if (statusName === "PROPERTY WALK-THROUGH") {
        this.props.navigation.navigate("PropertyDetails", {
          id: deal.id,
          video: taskArr[taskNo].videos,
        });
      } else {
        if (status == "Complete") {
          let progressTime = null;

          if (statusName !== "PROFILE REVIEW") {
          }

          this.props.navigation.navigate("TaskDetails", {
            task: taskArr[taskNo],
            status: status,
            time: time,
            progressTime: progressTime,
          });
        } else {
          this.props.navigation.navigate("TaskDetails", {
            task: taskArr[taskNo],
            status: status,
            time: null,
            progressTime: time,
          });
        }
      }
    }
  }

  async refresh() {
    this.setState({ refreshing: true });
    var db = firebase.firestore();
    let allLeadsId = "d22875df-8bfe-430f-8ab9-c33736223f47";
    let leads = this.props.TIA.client.custom_fields.filter(
      (item, index) => item.id === allLeadsId
    );
    let leadsIds = {};

    if (leads[0].value) {
      leads[0].value.map((item, index) => {
        leadsIds[item.id] = true;
      });
    }
    db.collection("notifications")
      .get()
      .then((querySnapshot) => {
        let arr = [];
        querySnapshot.forEach((doc) => {
          if (leadsIds[doc.id]) {
            this.props.setNotificationsObject({ id: doc.id, data: doc.data() });
            arr = arr.concat(Object.values(doc.data()));
          }
        });

        arr.sort(function (a, b) {
          return (
            new Date(b.dateUpdated.seconds * 1000) -
            new Date(a.dateUpdated.seconds * 1000)
          );
        });
        this.setState({ refreshing: false });
        this.props.setNotifications(arr);
      })
      .catch((error) => {
        alert(error.message);
        this.setState({ refreshing: false });
      });
  }

  componentDidMount() {
    this.refresh();
  }

  getStatusName(statusName, flag) {
    let keys = Object.keys(this.props.TIA.template);
    let index = keys.indexOf(statusName);

    if (index > 0) {
      if (flag) {
        return this.props.TIA.template[keys[index]][fields.appStageName];
      }
      return this.props.TIA.template[keys[index - 1]][fields.appStageName];
    } else {
      console.log(
        "\n\n\n",
        JSON.stringify(Object.keys(this.props.TIA.template))
      );
      console.log(
        statusName,
        " ",
        this.props.TIA.template[keys[index]][fields.appStageName],
        " ",
        index
      );
    }
  }

  render() {
    const { notifications, email, refreshing, raw, markedDates } = this.state;

    return (
      <MainLayout
        childrenHeader={
          <View style={stylesHome.header}>
            <Text style={[stylesHome.headerTitle, { fontSize: 20 }]}>
              Notifications
            </Text>
          </View>
        }
        childrenMenu={
          <View style={stylesHome.contentMenu}>
            <CalendarCard markedDates={markedDates} />
          </View>
        }
      >
        <SafeAreaView
          style={{ flex: 1, backgroundColor: MAIN_COLORS.BACKGROUND_BLACK }}
          keyboardShouldPersistTaps={true}
          behavior={Platform.OS === "ios" ? "padding" : "padding"}
          keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}
        >
          <NavigationEvents
            onWillFocus={this._onFocus}
            onWillBlur={this._onBlurr}
          />

          <ScrollView
            refreshControl={
              <RefreshControl
                refreshing={refreshing}
                onRefresh={() => this.refresh(email)}
              />
            }
            onPress={Keyboard.dismiss}
            accessible={false}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{ flexGrow: 1, width: "100%", height: "100%" }}
          >
            <View style={styles.scrollViewContentView}>
              <View style={styles.centerView}>
                {this.props.TIA.notifications.length ? (
                  this.props.TIA.notifications.map((item, index) => {
                    var msDiff =
                      new Date() - new Date(item.dateUpdated.seconds * 1000);
                    var diff = Math.floor(msDiff / (1000 * 60));
                    var minutes = diff;

                    return (
                      <View key={index}>
                        {item.statusName !== "MARKET UPDATES" && (
                          <TouchableOpacity
                            onPress={() =>
                              this.props.goToProjectDetails(
                                item.taskId,
                                item.statusName,
                                index
                              )
                            }
                            activeOpacity={0.8}
                            style={{
                              backgroundColor: MAIN_COLOR.BACKGROUND_COLOR_MAIN,
                              borderRadius: 10,
                              padding: 12,
                              marginBottom: 12,
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <View style={{ flexDirection: "row" }}>
                                <Image
                                  style={{
                                    width: 64,
                                    height: 64,
                                    borderRadius: 8,
                                    marginRight: 12,
                                  }}
                                  source={require("../../assets/home/portfolio_image_default.png")}
                                />
                                <View style={{ flexDirection: "column" }}>
                                  <Text
                                    style={{
                                      fontSize: 14,
                                      color: "#FFFFFF",
                                      fontFamily: MAIN_FONT.semi,
                                      marginBottom: 4,
                                    }}
                                  >
                                    {this.getStatusName(item.statusName, true)}
                                  </Text>
                                  <Text
                                    style={{
                                      fontSize: 12,
                                      color: "#A3A3A3",
                                      fontFamily: MAIN_FONT.regular,
                                      marginBottom: 4,
                                    }}
                                  >
                                    {item.taskName}
                                  </Text>
                                  <Time
                                    date={item.dateUpdated.seconds * 1000}
                                  />
                                </View>
                              </View>

                              <View style={{ flexDirection: "row" }}>
                                <TouchableOpacity
                                  style={{
                                    flexDirection: "row",
                                    flex: 1,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: 77,
                                    padding: 4,
                                    width: 32,
                                    height: 32,
                                  }}
                                >
                                  <Image
                                    style={{
                                      resizeMode: "contain",
                                      width: 18,
                                      height: 18,
                                    }}
                                    source={require("../../assets/three-dots.svg")}
                                  />
                                </TouchableOpacity>
                              </View>
                            </View>
                          </TouchableOpacity>
                        )}

                        <TouchableOpacity
                          onPress={() =>
                            this.props.goToProjectDetails(
                              item.taskId,
                              item.statusName,
                              index
                            )
                          }
                          activeOpacity={0.8}
                          style={{
                            backgroundColor: MAIN_COLOR.BACKGROUND_COLOR_MAIN,
                            borderRadius: 10,
                            padding: 12,
                            marginBottom: 12,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <View style={{ flexDirection: "row" }}>
                              <Image
                                style={{
                                  width: 64,
                                  height: 64,
                                  borderRadius: 8,
                                  marginRight: 12,
                                }}
                                source={require("../../assets/home/portfolio_image_default.png")}
                              />
                              <View style={{ flexDirection: "column" }}>
                                <Text
                                  style={{
                                    fontSize: 14,
                                    color: "#FFFFFF",
                                    fontFamily: MAIN_FONT.semi,
                                    marginBottom: 4,
                                  }}
                                >
                                  {this.getStatusName(item.statusName)}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: 12,
                                    color: "#A3A3A3",
                                    fontFamily: MAIN_FONT.regular,
                                    marginBottom: 4,
                                  }}
                                >
                                  {item.taskName}
                                </Text>
                                <Time date={item.dateUpdated.seconds * 1000} />
                              </View>
                            </View>

                            <View style={{ flexDirection: "row" }}>
                              <TouchableOpacity
                                style={{
                                  flexDirection: "row",
                                  flex: 1,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: 77,
                                  padding: 4,
                                  width: 32,
                                  height: 32,
                                }}
                              >
                                <Image
                                  style={{
                                    resizeMode: "contain",
                                    width: 18,
                                    height: 18,
                                  }}
                                  source={require("../../assets/three-dots.svg")}
                                />
                              </TouchableOpacity>
                            </View>
                          </View>
                        </TouchableOpacity>
                      </View>
                    );
                  })
                ) : (
                  <EmptyContent
                    title="Content"
                    subTitle="Please click on your assets under Purchases for details."
                  />
                )}
              </View>
            </View>
          </ScrollView>
          {this.state.isLoading ? <LOADER /> : null}
        </SafeAreaView>
      </MainLayout>
    );
  }
}

function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    CART: state.CART,
    TIA: state.TIA,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setNotifications,
      setNotificationsObject,
      setUser,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
