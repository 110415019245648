import { View, StyleSheet, Image, Text, ScrollView } from "react-native";
import React, { memo } from "react";
import { useSelector } from "react-redux";

//Fucntion
import { MAIN_COLOR } from "../../../Utility/Colors";
import { MAIN_FONT } from "../../../Utility/Fonts/font";

//Component
import MainLayout from "../../../Layout/Main";
import OpportunityItem from "../../../components/OpportunityItem";
import CalendarCard from "../../../Utility/ComponentCard/CalendarCard";
import NotificationCard from "../../../Utility/ComponentCard/NotificationCard";

const HomeComp = ({
  leads,
  allBrandingLogo,
  setStateSelectedIndex,
  setStateScreen,
  goToProjectDetails,
  markedDates,
}) => {
  const { client, notifications } = useSelector((state) => state.TIA);

  const { showCalendar } = useSelector((state) => state.PORTFOLIO_ROADMAP);

  return (
    <MainLayout
      childrenHeader={
        <View style={stylesHome.header}>
          <Text style={stylesHome.headerSubTitle}>Welcome back!</Text>
          <Text style={stylesHome.headerTitle}>{client?.name}</Text>
        </View>
      }
      childrenMenu={
        <View style={[stylesHome.contentMenu, { gap: showCalendar ? 22 : 0 }]}>
          <CalendarCard markedDates={markedDates} />
          <View style={stylesHome.menuSection}>
            <Text style={stylesHome.menuSectionTitle}>Notification</Text>
            {notifications.length &&
              notifications.slice(0, 3).map((noti, index) => {
                return (
                  <NotificationCard
                    key={index}
                    item={noti}
                    allBrandingLogo
                    goToProjectDetails={goToProjectDetails}
                  />
                );
              })}
          </View>
        </View>
      }
    >
      <View style={stylesHome.container}>
        <View style={stylesHome.contentTop}>
          {leads.length ? (
            <ScrollView showsVerticalScrollIndicator={false}>
              <Text style={stylesHome.titleContentCenterView}>
                All Purchase
              </Text>
              <View style={stylesHome.rowStartWrap}>
                {leads.length &&
                  leads.map((item, index) => {
                    const coLabelingLogo = allBrandingLogo[
                      index
                    ]?.tasks?.[0]?.custom_fields.find(
                      (one) => one.name === "Co-labeling logo"
                    )?.value?.[0].url;

                    return (
                      <OpportunityItem
                        item={item}
                        key={index}
                        index={index}
                        coLabelingLogo={coLabelingLogo}
                        onPress={() => {
                          setStateSelectedIndex(index);
                          setStateScreen("ProjectDetails");
                        }}
                      />
                    );
                  })}
              </View>
            </ScrollView>
          ) : (
            <View style={stylesHome.contentViewEmpty}>
              <Image
                style={{ height: 150, width: 150 }}
                resizeMode="contain"
                source={require("../../../assets/home/content.svg")}
              />
              <Text style={stylesHome.viewEmptyTitle}>Welcome to TIA</Text>
              <Text style={stylesHome.viewEmptySubTitle}>
                You’re on your way to better property management.
              </Text>
            </View>
          )}
        </View>
      </View>
    </MainLayout>
  );
};

export const stylesHome = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR_BLACK,
  },
  header: {
    gap: 4,
    display: "flex",
    alignItems: "flex-start",
  },

  headerSubTitle: {
    fontSize: 14,
    lineHeight: 21,
    fontWeight: 400,
    color: MAIN_COLOR.G600,
    fontFamily: MAIN_FONT.semi,
  },
  headerTitle: {
    fontSize: 16,
    fontWeight: 600,
    lightHeight: 24,
    color: MAIN_COLOR.BACKGROUND_COLOR,
    fontFamily: MAIN_FONT.semi,
  },
  contentTop: {
    flex: 1,
    paddingHorizontal: "2.5%",
    paddingVertical: "2.5%",
    borderRadius: 0,
  },

  rowStartWrap: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: 12,
    flexWrap: "wrap",
  },

  titleContentCenterView: {
    marginBottom: 16,
    fontSize: 20,
    fontWeight: 600,
    lightHeight: 30,
    color: MAIN_COLOR.BACKGROUND_COLOR,
    fontFamily: MAIN_FONT.semi,
  },

  contentViewEmpty: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },

  viewEmptyTitle: {
    fontSize: 20,
    fontWeight: 600,
    lightHeight: 30,
    textAlign: "center",
    color: MAIN_COLOR.G600,
    fontFamily: MAIN_FONT.regular,
    textAlign: "center",
    marginTop: 20,
  },

  viewEmptySubTitle: {
    fontSize: 16,
    fontWeight: 400,
    lightHeight: 24,
    color: MAIN_COLOR.G600,
    fontFamily: MAIN_FONT.regular,
    textAlign: "center",
    marginTop: 10,
  },

  contentMenu: {
    display: "flex",
    width: "100%",
    gap: 22,
  },

  menuSection: {
    display: "flex",
    gap: 12,
  },

  menuSectionTitle: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: 600,
    color: MAIN_COLOR.BACKGROUND_COLOR,
  },
});

export default memo(HomeComp);
