import React, { memo, useState } from "react";
import { View, Text } from "react-native";
import "chart.js/auto";
import "chartjs-adapter-moment";
import { Radar } from "react-chartjs-2";
import { TouchableOpacity } from "react-native";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";

//Functions
import { styles } from "../index.v2";
import { MAIN_COLORS } from "../../../Utility/Colors";
import { FONT_SIZE } from "../../../Utility/Fonts/font";
import {
  externalTooltipHandlerNew,
  formatDollar,
} from "../../../Utility/common";

ChartJS.register(ArcElement, Tooltip, Legend);

const SuburbInsight = () => {
  const [clauseActive, setClauseActive] = useState("building");
  const radarData = {
    labels: [
      "Affordability",
      ["Imm. Rent", "Potential"],
      ["Stock", "on Market"],
      ["Rental", "Yield"],
      ["Vacancy", "Rate"],
    ],
    datasets: [
      {
        data: [120, 140, 130, 135, 150],
        backgroundColor: (context) => {
          const bgColor = [
            "rgba(250, 191, 1, 0.3)",
            "rgba(250, 191, 1, 0.15)",
            "rgba(250, 191, 1, 0.05)",
          ];
          if (!context.chart.chartArea) return;
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0.1, bgColor[0]);
          gradientBg.addColorStop(0.5, bgColor[1]);
          gradientBg.addColorStop(1, bgColor[2]);
          return gradientBg;
        },
        borderColor: MAIN_COLORS.PRIMARY_COLOR,
        pointBorderWidth: 5.5,
        pointBackgroundColor: "#D3B100",
        pointRadius: ({ index }) => 0,
        pointHoverRadius: 4,
        borderWidth: 1,
      },
    ],
  };

  const max = Math.max(...[120, 140, 130, 135, 150]);

  const radarOptions = {
    hover: { mode: "index", intersect: false },
    tooltips: { mode: "index", intersect: false },
    aspectRatio: 5 / 4,
    scales: {
      r: {
        border: {
          dash: (context) => {
            if (context.index === 4) return [0, 0];

            return [3, 3];
          },
          color: "#34393D",
        },
        beginAtZero: true,
        ticks: { display: false, stepSize: max / 8, maxTicksLimit: 8 },
        grid: { color: "#34393D" },
        pointLabels: {
          font: { size: FONT_SIZE.small, weight: 500 },
          color: MAIN_COLORS.G600,
        },
        max: max,
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
        position: "nearest",
        callbacks: { label: (value) => formatDollar(value.raw) },
        external: (context) =>
          externalTooltipHandlerNew(
            context,
            { top: 0, right: 4, bottom: 0, left: 4 },
            "46px"
          ),
      },
    },
  };
  return (
    <View style={{ flexDirection: "row", gap: 16 }}>
      <View style={{ flex: 2 }}>
        <Text style={styles.subTitle}>Suburbs insights</Text>
        <View style={{ flexDirection: "row", gap: 16, flex: 1 }}>
          <View style={[styles.boxSection, { display: "flex", gap: 16 }]}>
            <Text style={[styles.subTitle, { marginBottom: 0 }]}>
              Suburb Statistics
            </Text>
            <View style={styles.rowCenterBetween}>
              <Text style={styles.textSection}>Vacancy rate</Text>
              <Text style={styles.valueSection}>0.22%</Text>
            </View>
            <View style={styles.rowCenterBetween}>
              <Text style={styles.textSection}>Population in 2021</Text>
              <Text style={styles.valueSection}>0.22%</Text>
            </View>
            <View style={styles.rowCenterBetween}>
              <Text style={styles.textSection}>Population today</Text>
              <Text style={styles.valueSection}>0.22%</Text>
            </View>
            <View style={styles.rowCenterBetween}>
              <Text style={styles.textSection}>No. of houses</Text>
              <Text style={styles.valueSection}>0.22%</Text>
            </View>
            <View style={styles.rowCenterBetween}>
              <Text style={styles.textSection}>No. of units</Text>
              <Text style={styles.valueSection}>0.22%</Text>
            </View>
            <View style={styles.rowCenterBetween}>
              <Text style={styles.textSection}>Personal income</Text>
              <Text style={styles.valueSection}>0.22%</Text>
            </View>
            <View style={styles.rowCenterBetween}>
              <Text style={styles.textSection}>Household income</Text>
              <Text style={styles.valueSection}>0.22%</Text>
            </View>
          </View>
          <View style={styles.boxSection}>
            <Text style={styles.subTitle}>Investment Profile</Text>
            <View
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Radar
                data={radarData}
                options={radarOptions}
                style={{ width: "100%", height: "100%" }}
              />
            </View>
          </View>
        </View>
      </View>

      <View style={{ flex: 1 }}>
        <Text style={styles.subTitle}>Building and Pest Clause</Text>
        <View style={styles.boxSection}>
          <View style={{ flexDirection: "row", gap: 8, marginBottom: 12 }}>
            <TouchableOpacity
              style={[
                styles.btnClause,
                clauseActive === "building" && styles.btnClauseAvtive,
              ]}
              onPress={() => setClauseActive("building")}
            >
              <Text
                style={[
                  styles.textSection,
                  {
                    color:
                      clauseActive === "building"
                        ? MAIN_COLORS.PRIMARY_COLOR
                        : MAIN_COLORS.G600,
                  },
                ]}
              >
                Building Clause
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                styles.btnClause,
                clauseActive === "pest" && styles.btnClauseAvtive,
              ]}
              onPress={() => setClauseActive("pest")}
            >
              <Text
                style={[
                  styles.textSection,
                  {
                    color:
                      clauseActive === "pest"
                        ? MAIN_COLORS.PRIMARY_COLOR
                        : MAIN_COLORS.G600,
                  },
                ]}
              >
                Pest Clause
              </Text>
            </TouchableOpacity>
          </View>
          <View style={{ gap: 8 }}>
            {clauseActive === "building" ? (
              <>
                <Text style={styles.subTitleSection}>
                  Building Report Clause
                </Text>
                <Text style={styles.textSection}>
                  The standard annexure for any building inspection allows a
                  purchaser to serve a notice on the seller within the specified
                  time (usually 14 or 21 days) requesting the seller to rectify
                  any MAJOR STRUCTURAL DEFECT only. The clause usually goes a
                  step further to limit any claim to the main building only
                  (unless other areas are expressly listed - such as a fence or
                  carport etc).The seller can either elect to (i) rectify before
                </Text>
              </>
            ) : (
              <>
                <Text style={styles.subTitleSection}>Pest Report Clause</Text>
                <Text style={styles.textSection}>
                  The standard annexure for any building inspection allows a
                  purchaser to serve a notice on the seller within the specified
                  time (usually 14 or 21 days) requesting the seller to rectify
                  any MAJOR STRUCTURAL DEFECT only. The clause usually goes a
                  step further to limit any claim to the main building only
                </Text>
              </>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default memo(SuburbInsight);
