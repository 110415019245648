import moment from "moment";
import { isEmpty } from "lodash";
import React, { memo, useState, useEffect, useRef } from "react";
import { View, StyleSheet, Text, ActivityIndicator } from "react-native";
import "chart.js/auto";
import "chartjs-adapter-moment";
import { Line } from "react-chartjs-2";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";

import {
  MARKET_INSIGHT_FIELDS,
  MARKET_INSIGHT_LABELS,
} from "../../../Utility/Fields/index.js";
import {
  formatDollar,
  externalTooltipBarHandler,
} from "../../../Utility/common.js";
import {
  FONT_SIZE,
  FONT_WEIGHT,
  MAIN_FONT,
} from "../../../Utility/Fonts/font.js";
import { styles } from "../index.v2.js";
import { getPublicMarketHistory } from "../../../Clickup/api";
import { MAIN_COLORS } from "../../../Utility/Colors/index.js";

import FlastList from "../../../components/FlastList.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const SpecificData = ({ name }) => {
  const [marketHistories, setMarketHistories] = useState([]);
  const [loading, setLoading] = useState(false);

  const calcPercentageGrowth = (newValue, oldValue) => {
    const value = (((newValue - oldValue) / (oldValue || 1)) * 100).toFixed(1);
    return value > 0 ? `+ ${value}` : value;
  };

  const fetchData = async (name) => {
    try {
      setLoading(true);
      const response = await getPublicMarketHistory(name);
      if (!isEmpty(response.data)) {
        const result = MARKET_INSIGHT_FIELDS.map((one) => {
          return {
            key: one,
            data: response.data[one],
            ...MARKET_INSIGHT_LABELS[one],
          };
        });

        const data = result.map((market) => {
          return {
            title: market.title,
            subTitle: market.subTitle,
            formatter: market.formatter,
            ySuffix: market?.ySuffix,
            unitCard: market?.unitCard,
            dataset: market?.data
              .filter((one, index) => !(index % 3))
              .map((item) => ({
                x: item.label,
                y: item.value,
              })),
          };
        });
        setMarketHistories(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (name) fetchData(name);
  }, [name]);

  return (
    <View
      style={{ height: "max-content", width: "100%", position: "relative" }}
    >
      {loading && (
        <ActivityIndicator color={MAIN_COLORS.PRIMARY_COLOR} size="large" />
      )}
      <FlastList
        width={368 + 16}
        height={429}
        data={marketHistories}
        paginate
        styleContainer={{ flexDirection: "row", gap: 16 }}
        renderItem={({ item, index }) => {
          const value = calcPercentageGrowth(
            Number(item?.dataset?.slice(-1)?.[0]?.y ?? 0),
            Number(item?.dataset?.[0].y ?? 0)
          );

          return (
            <View style={_styles.item} key={index}>
              <View style={{ marginTop: 18, alignItems: "center" }}>
                <Text style={[styles.subTitleSection]}>{item.title}</Text>
                <Text style={styles.textSection}>{name} market insights</Text>
              </View>
              <View
                style={{ flexDirection: "row", gap: 10, alignItems: "center" }}
              >
                <Text style={[styles.textSection, { fontSize: 12 }]}>
                  {item.subTitle}
                </Text>
                <Text style={_styles.valueItem}>
                  {item?.yPrefix}
                  {item.formatter
                    ? item.formatter(item.dataset?.slice(-1)[0].y)
                    : item.dataset?.slice(-1)[0].y}
                  {item?.unitCard || item?.ySuffix}({value}%)
                </Text>
                <Text style={[styles.textSection, { fontSize: 12 }]}>
                  Change 12M
                </Text>
              </View>
              <RenderChart width={"100%"} height={"100%"} result={item} />
            </View>
          );
        }}
      />
    </View>
  );
};

export const RenderChart = ({ width, height, result = [] }) => {
  const chartRef = useRef(null);

  const minY = Math.min(...result.dataset?.map(({ y }) => y));
  const maxY = Math.max(...result.dataset?.map(({ y }) => y));

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    hover: { mode: "index", intersect: false },
    tooltips: { mode: "index", intersect: false },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
        position: "nearest",
        cornerRadius: 20,
        callbacks: {
          label: (value) => {
            const suffix = result.unitCard || result.ySuffix;
            if (suffix) return `${value.raw.y}${suffix}`;
            return formatDollar(value.raw.y);
          },
        },
        external: (context) => externalTooltipBarHandler(context),
      },
    },
    scales: {
      x: {
        font: { family: MAIN_FONT.regular, size: 12 },
        grid: { drawTicks: false, color: "#7D888F80" },
        border: { color: "#3F4448", dash: [3, 3] },
        ticks: {
          callback: (label, index) =>
            moment(result.dataset[index].x).format("MMM"),
        },
      },
      y: {
        border: { dash: [3, 3], color: "#3F4448" },
        grid: {
          drawTicks: false,
          color: function (context) {
            if (context.index === 3) return "rgba(0,0,0,0)";
            return "#3F4448";
          },
        },
        beginAtZero: false,
        min: minY,
        max: maxY + maxY * 0.1,
        ticks: {
          display: false,
          maxTicksLimit: 5,
          stepSize: (maxY + maxY * 0.1 - minY) / 5,
        },
      },
      y1: {
        stacked: true,
        position: "right",
        grid: { display: false },
        ticks: { display: false },
        border: { color: "#3F4448" },
      },
    },
  };

  const data = {
    datasets: [
      {
        data: result.dataset,
        backgroundColor: (context) => {
          const bgColor = [
            "rgba(255, 255, 255, 0.3)",
            "rgba(255, 255, 255, 0.15)",
            "rgba(255, 255, 255, 0.05)",
          ];
          if (!context.chart.chartArea) return;
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0.1, bgColor[0]);
          gradientBg.addColorStop(0.5, bgColor[1]);
          gradientBg.addColorStop(1, bgColor[2]);
          return gradientBg;
        },
        borderColor: MAIN_COLORS.BACKGROUND_WHITE,
        pointBorderWidth: 5.5,
        pointBackgroundColor: MAIN_COLORS.PRIMARY_COLOR,
        pointRadius: ({ index }) => 0,
        pointHoverRadius: 6,
        lineTension: 0.5,
        borderWidth: 2,
        fill: true,
        spanGaps: true,
        clip: false,
      },
    ],
  };

  useEffect(() => {
    const chart = chartRef.current;
    chart?.tooltip?.setActiveElements([]);
    chart?.update();
  }, []);

  return (
    <View style={{ width: width, flex: 1 }}>
      <Line options={options} data={data} ref={chartRef} />
    </View>
  );
};

const _styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    gap: 16,
    height: 429,
  },

  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 12,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#4B555D99",
    paddingHorizontal: 4,
    paddingBottom: 22,
    marginRight: 16,
    height: "100%",
  },

  valueItem: {
    paddingHorizontal: 12,
    paddingVertical: 6,
    flexDirection: "row",
    gap: 5,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    borderRadius: 36,
    borderWidth: 1,
    borderColor: "#4B555D99",
    fontSize: FONT_SIZE.medium,
    lineHeight: 21,
    fontWeight: FONT_WEIGHT.bold,
    color: MAIN_COLORS.PRIMARY_COLOR,
    fontFamily: MAIN_FONT.regular,
  },
});

export default memo(SpecificData);
