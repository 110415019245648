import React from "react";
import {
  Text,
  View,
  Image,
  Platform,
  SafeAreaView,
  TouchableOpacity
} from "react-native";
import * as Progress from "react-native-progress";

import { MAIN_FONT } from "../../Utility/Fonts/font";
import { MAIN_COLOR } from "../../Utility/Colors";
import { CheckListItem } from "./CheckListItem/indexv2";

// redux
import { connect } from "react-redux";

// style
import styles from "./stylev2";

class CheckListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checklists: props.checklists,
      currentPage: 0,
      itemsPerPage: 3
    };
  }

  getTotalPages = () => {
    const { checklists } = this.state;
    const items = checklists[0]?.items ?? [];
    return Math.ceil(items.length / this.state.itemsPerPage);
  };

  changePage = (direction) => {
    this.setState((prevState) => {
      const totalPages = this.getTotalPages();

      if (direction === "prev" && prevState.currentPage > 0) {
        return { currentPage: prevState.currentPage - 1 };
      } else if (direction === "next" && prevState.currentPage < totalPages - 1) {
        return { currentPage: prevState.currentPage + 1 };
      }
      return null;
    });
  };


  render() {
    const { checklists, currentPage, itemsPerPage } = this.state;
    const items = checklists[0]?.items ?? [];
    const lengthChecked = items.filter(item => item.resolved === true).length;

    const startIndex = currentPage * itemsPerPage;
    const paginatedItems = items.slice(startIndex, startIndex + itemsPerPage);

    return (
      <View>
        <Text
          style={{
            fontSize: 16,
            color: "#FFFFFF",
            fontFamily: MAIN_FONT.semi,
            marginBottom: 16,
          }}
        >
          {checklists[0]?.name ?? ""}
        </Text>
        <View
          style={{
            flexDirection: "column",
            padding: 12,
            borderRadius: 10,
            gap: 16,
            backgroundColor: MAIN_COLOR.BACKGROUND_COLOR_MAIN,
          }}
        >
          <View
            style={{
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => this.changePage("prev")}
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: 28,
                height: 28,
                borderRadius: 32,
                backgroundColor: "#120F0E",
                opacity: currentPage > 0 ? 1 : 0,
              }}
              pointerEvents={currentPage > 0 ? "auto" : "none"}
            >
              <Image
                source={require("../../assets/angle-left.svg")}
                resizeMode="contain"
                style={{
                  height: 10,
                  width: 5,
                }}
              />
            </TouchableOpacity>

            <Text
              style={{
                fontSize: 14,
                color: "#FFFFFF",
                fontFamily: MAIN_FONT.semi,
              }}
            >
              {lengthChecked}/{items.length}
            </Text>

            <TouchableOpacity
              onPress={() => this.changePage("next")}
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: 28,
                height: 28,
                borderRadius: 32,
                backgroundColor: "#120F0E",
                opacity: currentPage < this.getTotalPages() - 1 ? 1 : 0,
              }}
              pointerEvents={currentPage < this.getTotalPages() - 1 ? "auto" : "none"}
            >
              <Image
                source={require("../../assets/angle-right.svg")}
                resizeMode="contain"
                style={{
                  height: 10,
                  width: 5,
                }}
              />
            </TouchableOpacity>
          </View>

          <View
            style={{
              flexDirection: "column",
              paddingHorizontal: 8,
              paddingVertical: 16,
              gap: 16,
              borderRadius: 8,
              backgroundColor: "#120F0E",
            }}
          >
            {paginatedItems?.map((item, index) => (
              <CheckListItem key={index} item={item} />
            ))}
          </View>
        </View>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckListComponent);
