import React, { memo } from "react";
import { Text, View } from "react-native";
import { MAIN_FONT } from "../../../Utility/Fonts/font";
import { MAIN_COLORS } from "../../../Utility/Colors";

// Component
import Time from "../../../components/Time";

const Date = ({ task, title, time, due }) => {
  return (
    <View
      style={{
        flexDirection: "column",
        gap: 8,
        backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
        borderRadius: 12,
        padding: 12,
      }}
    >
      <Text
        style={{
          fontSize: 16,
          fontWeight: 600,
          color: MAIN_COLORS.TEXT_LIGHT,
          fontFamily: MAIN_FONT.regular,
          fontFamily: MAIN_FONT.semi,
        }}
      >
        {title}
      </Text>

      <Time due={due} date={time} />
    </View>
  );
};

export default memo(Date);
