import { BlurView } from "expo-blur";
import React, { memo, useState } from "react";
import {
  TouchableOpacity,
  StyleSheet,
  View,
  Image,
  Text,
  Pressable,
} from "react-native";

//Functions
import { MAIN_COLORS } from "../../Colors";
import { FONT_SIZE, FONT_WEIGHT, MAIN_FONT } from "../../Fonts/font";

//Components
import Time from "../../../components/Time";

const PropertyCard = ({ item, style, close, onPress, onRemove }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Pressable
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
    >
      <TouchableOpacity style={[styles.container, style]} onPress={onPress}>
        {isHovered && close && (
          <TouchableOpacity style={styles.btnRemove} onPress={onRemove}>
            <BlurView intensity={10} style={styles.btnRemoveBlur}>
              <Image
                style={{ width: 10, height: 10 }}
                source={require("../../../assets/home/close.png")}
              />
            </BlurView>
          </TouchableOpacity>
        )}
        <View style={styles.attached}>
          <Image
            style={{ width: 48, height: 21 }}
            source={require("../../../assets/home/accountains.svg")}
          />
        </View>
        <Image
          style={{ width: "100%", flex: 1, transform: [{ scale: 1.2 }] }}
          resizeMode="content"
          source={
            item.image ||
            require("../../../assets/home/portfolio_image_default.png")
          }
        />
        <View style={styles.info}>
          <BlurView intensity={10} style={styles.blurInfo}>
            <Text style={styles.portfolioName}>
              {item?.Suburb}, {item?.state}
            </Text>
            <View style={styles.rowCenterBetween}>
              <Text style={styles.text}>Update: </Text>
              <Time date={item?.date_updated * 1} />
            </View>
            <View style={styles.rowCenterBetween}>
              <View
                style={{ flexDirection: "row", gap: 8, alignItems: "center" }}
              >
                <Image
                  style={{ width: 20, height: 20 }}
                  source={require("../../../assets/home/bed_gray.svg")}
                />
                <Text style={{ color: MAIN_COLORS.TEXT_GRAY }}>
                  {item?.beds ?? 0} Bed
                </Text>
              </View>
              <View
                style={{ flexDirection: "row", gap: 8, alignItems: "center" }}
              >
                <Image
                  style={{ width: 20, height: 20 }}
                  source={require("../../../assets/home/bath_gray.svg")}
                />
                <Text style={{ color: MAIN_COLORS.TEXT_GRAY }}>
                  {item?.baths ?? 0} Bath
                </Text>
              </View>
              <View
                style={{ flexDirection: "row", gap: 8, alignItems: "center" }}
              >
                <Image
                  style={{ width: 20, height: 20 }}
                  source={require("../../../assets/home/car_gray.svg")}
                />
                <Text style={{ color: MAIN_COLORS.TEXT_GRAY }}>
                  {item?.cars ?? 0} Car
                </Text>
              </View>
              <View
                style={{ flexDirection: "row", gap: 8, alignItems: "center" }}
              >
                <Image
                  style={{ width: 20, height: 20 }}
                  source={require("../../../assets/home/size_gray.svg")}
                />
                <Text style={{ color: MAIN_COLORS.TEXT_GRAY }}>
                  {item?.area ?? 0} m2
                </Text>
              </View>
            </View>
          </BlurView>
        </View>
      </TouchableOpacity>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    width: 380,
    height: 300,
    borderRadius: 12,
    backgroundColor: MAIN_COLORS.BACKGROUND_WHITE,
    overflow: "hidden",
  },

  attached: {
    width: 66,
    height: 33,
    borderRadius: 4,
    backgroundColor: "#FFFFFF99",
    position: "absolute",
    top: 12,
    right: 12,
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 4,
    elevation: 2,
  },

  info: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "auto",
    backgroundColor: "#19232BD9",
  },
  blurInfo: {
    width: "100%",
    height: "100%",
    paddingHorizontal: 14,
    paddingVertical: 10,
    display: "flex",
    gap: 8,
  },
  portfolioName: {
    fontSize: FONT_SIZE.large,
    lineHeight: 24,
    fontWeight: FONT_WEIGHT.bold,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },
  text: {
    fontSize: FONT_SIZE.small,
    lineHeight: 18,
    fontWeight: FONT_WEIGHT.medium,
    color: MAIN_COLORS.TEXT_GRAY,
    fontFamily: MAIN_FONT.regular,
  },

  rowCenterBetween: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 6,
  },

  btnRemove: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 2,
    backgroundColor: "#333D4473",
    width: 36,
    height: 36,
    borderBottomLeftRadius: 10.24,
    overflow: "hidden",
  },

  btnRemoveBlur: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottomLeftRadius: 10.24,
  },
});

export default memo(PropertyCard);
