import moment from "moment";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import {
  Animated,
  Easing,
  Image,
  Text,
  View,
  TouchableOpacity,
} from "react-native";

//Functions
import styles from "./style";
import { MAIN_COLOR } from "../../../../Utility/Colors";
import { getListMarketHistory } from "../../../../Clickup/api";

//Components
import Costs from "./Costs";
import { BlurView } from "expo-blur";
import GrowthChart from "./GrowthChart";
import Carousel from "react-native-reanimated-carousel";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";

const PurchaseDetails = ({ properties, setPurchaseSelected }) => {
  const opacity = useRef(new Animated.Value(0)).current;

  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const animate = (easing) => {
    opacity.setValue(0);
    Animated.timing(opacity, {
      toValue: 1,
      duration: 800,
      easing,
      useNativeDriver: true,
    }).start();
  };

  const sizeWidth = opacity.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 800],
  });

  const animatedStyles = [
    {
      opacity,
      width: sizeWidth,
    },
  ];

  const formatPropertyText = (quantity, unitLabel) => {
    const pluralizedUnit = quantity !== 0 ? `${unitLabel}s` : unitLabel;
    return `${quantity} ${pluralizedUnit}`;
  };

  const onClose = () => setPurchaseSelected(null);

  useEffect(() => {
    animate(Easing.elastic(0));
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        setResult(null);
        setLoading(true);
        const payload = {
          state: properties["PROPERTY STATE"],
          property_suburb: properties["PROPERTY SUBURB"],
          no_bed_room: properties["NO. OF BEDROOMS"] * 1,
          purchase_date: moment(properties["Purchase date "] * 1).format(
            "DD-MM-YYYY"
          ),
          purchase_price: properties["Purchase Price"] * 1,
          id: properties.id,
        };
        const response = await getListMarketHistory(payload);
        const {
          sales_median_12mths,
          growth_percentage,
          potential_value,
          potential_equity,
        } = response.data;

        const data = [];
        let totalPotentialEquity = 0;
        const purchasedAvm = sales_median_12mths[0].sales_median_12mths;
        const percentageAvm = payload["purchase_price"] / purchasedAvm;
        for (let i = 0; i <= sales_median_12mths.length - 1; i++) {
          totalPotentialEquity += sales_median_12mths[i].potential_equity;
          data.push({
            date:
              sales_median_12mths[i].end_date ||
              sales_median_12mths[i].end_date_tz,
            value:
              i === 0
                ? Number(properties["Purchase Price"])
                : sales_median_12mths[i].sales_median_12mths * percentageAvm,
          });
        }

        const growth_percent_temp =
          ((data[data.length - 1].value - data[0].value) * 100) / data[0].value;

        const growth_percent = growth_percent_temp ?? 0;

        setResult({
          sales: data,
          growthPercentage: growth_percent,
          potentialValue: potential_value,
          potentialEquity: potential_equity,
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [properties]);

  const images = properties["Image IDs"]
    ? properties["Image IDs"]
        .split(",")
        .map(
          (url) =>
            `https://insights.proptrack.com/imagery/250x165/${
              url.split(".")[0]
            }/image.jpg`
        )
    : properties["PROPERTY IMAGE URL"];

  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <Animated.View style={[styles.container, animatedStyles]}>
        <View style={[styles.flexRow, { gap: 16 }]}>
          <View style={[styles.imageContainer]}>
            {images ? (
              Array.isArray(images) ? (
                <Carousel
                  loop={false}
                  width={228}
                  height={"100%"}
                  autoPlay={true}
                  style={{ borderRadius: 8, width: 228 }}
                  data={images}
                  scrollAnimationDuration={1000}
                  pagingEnabled
                  renderItem={({ index }) => (
                    <Image
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                      key={index}
                      resizeMode="cover"
                      source={images[index]}
                    />
                  )}
                />
              ) : (
                <Image
                  style={{
                    height: "100%",
                    transform: [{ scale: 1.5 }],
                  }}
                  resizeMode="contain"
                  source={images}
                />
              )
            ) : (
              <Image
                style={{
                  height: "100%",
                  transform: [{ scale: 1 }],
                  backgroundColor: "#F5F6FA",
                }}
                resizeMode="contain"
                source={require("../../../../assets/portfolio-roadmap/no_img.png")}
              />
            )}
          </View>
          <View style={styles.info}>
            <View style={{ gap: 8 }}>
              <View style={styles.nameWrap}>
                <Text style={styles.name} numberOfLines={2}>
                  {properties["PROPERTY STREET ADDRESS"]}
                </Text>
                <TouchableOpacity style={styles.btnGoBack} onPress={onClose}>
                  <Image
                    resizeMode="contain"
                    style={{ height: 22, width: 22, margin: "auto" }}
                    source={require("../../../../assets/portfolio-roadmap/arrow_right_active.svg")}
                  />
                </TouchableOpacity>
              </View>
              <View
                style={[
                  styles.flexRow,
                  { gap: 4, alignItems: "center", marginLeft: -2 },
                ]}
              >
                <Image
                  style={{ width: 20, height: 20 }}
                  resizeMode="contain"
                  source={require("../../../../assets/location.png")}
                />
                <Text style={styles.address}>
                  {properties["PROPERTY SUBURB"]} {properties["PROPERTY STATE"]}{" "}
                  {properties["PROPERTY POSTCODE"]}
                </Text>
              </View>
            </View>
            <View style={styles.params}>
              <View style={styles.param}>
                <Image
                  resizeMode="contain"
                  style={styles.icon}
                  source={require("../../../../assets/home/bed.svg")}
                />
                <Text style={styles.paramValue}>
                  {formatPropertyText(properties["NO. OF BEDROOMS"], "Bed")}
                </Text>
              </View>
              <View style={styles.param}>
                <Image
                  resizeMode="contain"
                  style={styles.icon}
                  source={require("../../../../assets/home/bath.svg")}
                />
                <Text style={styles.paramValue}>
                  {formatPropertyText(properties["NO. OF BATHROOMS"], "Bath")}
                </Text>
              </View>
              <View style={styles.param}>
                <Image
                  resizeMode="contain"
                  style={styles.icon}
                  source={require("../../../../assets/home/car.svg")}
                />
                <Text style={styles.paramValue}>
                  {formatPropertyText(properties["# OF PARKING"], "Car")}
                </Text>
              </View>
              <View style={styles.param}>
                <Image
                  resizeMode="contain"
                  style={styles.icon}
                  source={require("../../../../assets/home/size.svg")}
                />
                <Text style={styles.paramValue}>
                  {properties["AREA (M2)"]}m2
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={[styles.flexRow, { gap: 13, marginTop: 22 }]}>
          <GrowthChart
            result={result}
            properties={properties}
            loading={loading}
          />
          <Costs result={result} properties={properties} />
        </View>
      </Animated.View>
    </OutsideClickHandler>
  );
};

export default PurchaseDetails;
