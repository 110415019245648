import React from "react";
import lodash from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Text, View, Image, StyleSheet, TouchableOpacity } from "react-native";

//Functions
import { MAIN_COLORS } from "../../Utility/Colors";
import { formatDollar } from "../../Utility/common";
import { toastConfig } from "../../configs/toastConfig";
import { setEquity, setClient } from "../../Reducer/tia";
import { FONT_SIZE, FONT_WEIGHT, MAIN_FONT } from "../../Utility/Fonts/font";
import {
  getProperty,
  removeProperty,
  getDetailClient,
  removeCustomFeildValue,
  getTotalPotentialEquity,
} from "../../Clickup/api";

//Components
import Modal from "../../components/Modal";
import MainLayout from "../../Layout/Main";
import SearchProperty from "./SearchProperty";
import Toast from "react-native-toast-message";
import PortfolioItem from "./PortfolioItem/index.v2";
import LOADER from "../../Utility/ComponentCard/Loader";
import EmptyContent from "../../components/EmptyContent";
import PropertyCard from "../../Utility/ComponentCard/PropertyCard";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      properties: [],
      equity: 0,
      total: 0,
      refreshing: false,
      isLoading: false,
      currentComponent: "index",
      listNonTIAProperties: [],
      visibleRemoveProperty: false,
      propertyRemove: {},
      item: null,
      index: null,
      totalPotentialValue: 0,
      selectedPropertyId: null,
    };
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps) {
    const currentValue = this.props.TIA.client.custom_fields?.find(
      (item) => item.name === "Properties"
    )?.value;
    const oldValue = prevProps.TIA.client.custom_fields?.find(
      (item) => item.name === "Properties"
    )?.value;
    if (!lodash.isEqual(currentValue, oldValue)) {
      this.getListPropertyNonTIA(this.props.TIA.client);
    }
  }

  async fetchPropertyData(id) {
    try {
      const propertyData = await getProperty(id);
      if (propertyData?.err) return null;
      return {
        ...propertyData?.custom_fields?.reduce(
          (fields, field) => ({
            ...fields,
            [field.name]: field.value,
          }),
          {}
        ),
        id: propertyData.id,
        date_updated: propertyData.date_updated,
      };
    } catch (error) {
      return null;
    }
  }

  async fetchTotalPotentialEquity(list_id) {
    try {
      const totalPotentialEquity = await getTotalPotentialEquity(list_id);
      if (totalPotentialEquity?.err) return 0;
      return totalPotentialEquity?.data || 0;
    } catch (error) {
      return null;
    }
  }
  async getListPropertyNonTIA(_client) {
    const { client } = this.props.TIA;
    const data = _client || client;
    const propertyIds = data?.custom_fields
      ?.find((one) => one.name === "Properties")
      ?.value?.map((item) => item.id);

    const opportunityIds = this.props.TIA.opportunities
      ?.filter((opportunity) => {
        const { status, "Proposed Property": proposedProperty } = opportunity;
        return (
          status?.status?.toUpperCase() === "MARKET UPDATES" &&
          !!proposedProperty?.[0]?.id
        );
      })
      .map((item) => item["Proposed Property"][0].id);

    const listPropertyIds = [...(propertyIds || []), ...(opportunityIds || [])];

    if (!listPropertyIds.length) return;
    const propertyDataPromises = listPropertyIds.map(this.fetchPropertyData);
    const totalPotentialEquity = await this.fetchTotalPotentialEquity(
      listPropertyIds
    );
    // const totalPotentialEquity = await this.fetchTotalPotentialEquity([
    //   "86cwumev2",
    // ]);
    this.setState({ totalPotentialValue: totalPotentialEquity });
    this.setState({ isLoading: true });
    await Promise.all(propertyDataPromises).then((propertyData) => {
      let listNonTIAProperty = [];
      let listTIAProperty = [];
      propertyData.forEach((item) => {
        if (item) {
          item["Purchased By"]
            ? listNonTIAProperty.push(item)
            : listTIAProperty.push(item);
        }
      });

      this.setState({
        listNonTIAProperties: listNonTIAProperty.map((object) => ({
          purchase_by: object["Purchased By"],
          image: object["Image IDs"]
            ? `https://insights.proptrack.com/imagery/500x415/${
                object["Image IDs"].split(",")[0].split(".")[0]
              }/image.jpg`
            : object["PROPERTY IMAGE URL"],
          purchase_date: object["Purchase date "],
          sold_price: object["Comparable Property Purchase Price"],
          url: object["Comparable Property URL"],
          beds: object["Comparable Property Bed"] || object["NO. OF BEDROOMS"],
          cars: object["Comparable Property Car"] || object["# OF PARKING"],
          baths:
            object["Comparable Property Bath"] || object["NO. OF BATHROOMS"],
          area: object["Comparable Property Land Size"] || object["AREA (M2)"],
          price: object["Purchase Price"],
          growth: object["Growth (%)"],
          current: object["Current Potential Value"],
          date_updated: object["date_updated"],
          Suburb:
            object["PROPERTY STREET ADDRESS"] +
            ", " +
            object["PROPERTY SUBURB"],
          id: object.id,
          state: object["PROPERTY STATE"],
          core_suburb: object["PROPERTY SUBURB"],
          net_cash_flow: object["Annual Net Cashflow"],
          gross_cash_flow: object["Annual Gross Income"],
          rent_per_week: object["CURRENT RENTAL RATE P/W $"],
          gross_yield: object["Annual Gross Yield"],
          net_yeild: object["Annual Net Yield %"],
        })),
      });

      this.setState({
        properties: listTIAProperty.map((object) => ({
          purchase_by: object["Purchased By"],
          image: object["Image IDs"]
            ? `https://insights.proptrack.com/imagery/250x165/${
                object["Image IDs"].split(",")[0].split(".")[0]
              }/image.jpg`
            : object["PROPERTY IMAGE URL"],
          purchase_date: object["Purchase date "],
          sold_price: object["Comparable Property Purchase Price"],
          url: object["Comparable Property URL"],
          beds: object["Comparable Property Bed"] || object["NO. OF BEDROOMS"],
          cars: object["Comparable Property Car"] || object["# OF PARKING"],
          baths:
            object["Comparable Property Bath"] || object["NO. OF BATHROOMS"],
          area: object["Comparable Property Land Size"] || object["AREA (M2)"],
          price: object["Purchase Price"],
          growth: object["Growth (%)"],
          current: object["Current Potential Value"],
          date_updated: object["date_updated"],
          Suburb:
            object["PROPERTY STREET ADDRESS"] +
            ", " +
            object["PROPERTY SUBURB"],
          id: object.id,
          state: object["PROPERTY STATE"],
          core_suburb: object["PROPERTY SUBURB"],
          net_cash_flow: object["Annual Net Cashflow"],
          gross_cash_flow: object["Annual Gross Income"],
          rent_per_week: object["CURRENT RENTAL RATE P/W $"],
          gross_yield: object["Annual Gross Yield"],
          net_yeild: object["Annual Net Yield %"],
        })),
      });
    });
    this.setState({ isLoading: false });
  }

  async getInfoClient() {
    const _client = await getDetailClient(this.props.TIA.client.id);
    this.props.setClient({ ..._client, email: this.props.TIA.client.email });
    this.getListPropertyNonTIA(_client);
  }

  setLoading = (value) => {
    this.setState({ isLoading: value });
  };

  handleSelectProperty = (propertyId) => {
    this.setState({ selectedPropertyId: propertyId });
  };

  componentDidMount() {
    this.getInfoClient();
  }

  render() {
    const {
      properties,
      equity,
      total,
      refreshing,
      item: itemSelected,
      index,
      isLoading,
      listNonTIAProperties,
      visibleRemoveProperty,
      propertyRemove,
      currentComponent,
      totalPotentialValue,
      selectedPropertyId,
    } = this.state;

    const handleRemoveProperty = async () => {
      try {
        const { client } = this.props.TIA;

        this.setState({ isLoading: true });
        this.setState({ visibleRemoveProperty: false });
        const { id: field_id, value } = client.custom_fields.find(
          (item) => item.name === "Properties"
        );
        const { team_id } = value.find((item) => item.id === propertyRemove.id);

        const result = await removeCustomFeildValue(
          propertyRemove.id,
          field_id,
          team_id
        );
        await removeProperty(propertyRemove.id, team_id);
        if (result?.err) {
          Toast.show({
            type: "error",
            text1: "Delete failed!",
            position: "top",
            visibilityTime: 3000,
          });
        } else {
          const _client = {
            ...client,
            ["custom_fields"]: lodash.map(client["custom_fields"], (field) => {
              if (field.name === "Properties") {
                return {
                  ...field,
                  ["value"]: lodash.filter(
                    field["value"],
                    (item) => item.id !== propertyRemove.id
                  ),
                };
              }
              return field;
            }),
          };
          this.props.setClient({
            ..._client,
            email: this.props.TIA.client.email,
          });
          await this.getListPropertyNonTIA(_client);
          Toast.show({
            type: "success",
            text1: "Delete successfully!",
            position: "top",
            visibilityTime: 3000,
          });
        }
      } finally {
        this.setState({ isLoading: false });
      }
    };

    return (
      <>
        <MainLayout
          childrenHeader={
            <View style={{ justifyContent: "center", height: "100%" }}>
              {currentComponent === "SearchProperty" ? (
                <View
                  style={[
                    styles.rowCenterBetween,
                    { justifyContent: "flex-start", gap: 16 },
                  ]}
                >
                  <TouchableOpacity
                    onPress={() =>
                      this.setState({
                        currentComponent: "index",
                        index: null,
                      })
                    }
                  >
                    <Image
                      style={{ width: 30, height: 30 }}
                      source={require("../../assets/home/back.svg")}
                    />
                  </TouchableOpacity>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text style={styles.headerTitle}>Portfolio </Text>
                    <Image
                      style={{
                        width: 30,
                        height: 30,
                        transform: [{ rotate: "180deg" }],
                      }}
                      source={require("../../assets/home/back.svg")}
                    />
                    <Text style={[styles.headerTitle, { color: "#D3B100" }]}>
                      Add Property
                    </Text>
                  </View>
                </View>
              ) : (
                <Text style={styles.headerTitle}>Portfolio</Text>
              )}
            </View>
          }
          childrenMenu={
            <View
              style={{
                display: "flex",
                gap: 16,
                position: "relative",
                height: "100%",
              }}
            >
              <View style={styles.containerMenu}>
                <View style={styles.rowCenterBetween}>
                  <Text style={styles.text}>Potential equity:</Text>
                  <Text
                    style={[
                      styles.menuTitle,
                      { color: MAIN_COLORS.PRIMARY_COLOR },
                    ]}
                  >
                    {formatDollar(totalPotentialValue)}
                  </Text>
                </View>
                <View style={styles.rowCenterBetween}>
                  <TouchableOpacity
                    style={styles.btnAddProperty}
                    onPress={() =>
                      this.setState({
                        currentComponent: "SearchProperty",
                        index: null,
                      })
                    }
                  >
                    <Image
                      style={{ width: 18, height: 18 }}
                      source={require("../../assets/home/add-circle.svg")}
                    />
                    <Text
                      style={[
                        styles.text,
                        { color: MAIN_COLORS.PRIMARY_COLOR },
                      ]}
                    >
                      Add Property
                    </Text>
                  </TouchableOpacity>
                  <Image
                    style={{ width: 80, height: 30 }}
                    source={require("../../assets/home/undulating.svg")}
                  />
                </View>
              </View>

              {properties?.length && (
                <>
                  <Text style={styles.menuTitle}>Purchases</Text>
                  {properties.map((property, index) => {
                    return (
                      <PropertyCard
                        style={{
                          width: "100%",
                          borderColor: MAIN_COLORS.PRIMARY_COLOR,
                          borderWidth: property.id === selectedPropertyId ? 1 : 0,
                        }}
                        item={property}
                        onPress={() => {
                          this.handleSelectProperty(property.id);
                          this.setState({
                            item: property,
                            index,
                            currentComponent: "index",
                          });
                        }}
                      />
                    );
                  })}
                </>
              )}

              {listNonTIAProperties?.length && (
                <>
                  <Text style={styles.menuTitle}>Imported Properties</Text>
                  {listNonTIAProperties.map((nonTIA, index) => {
                    return (
                      <PropertyCard
                        style={{
                          width: "100%",
                          borderColor: MAIN_COLORS.PRIMARY_COLOR,
                          borderWidth: nonTIA.id === selectedPropertyId ? 1 : 0,
                        }}
                        item={nonTIA}
                        close
                        onPress={() => {
                          this.handleSelectProperty(nonTIA.id);
                          this.setState({
                            item: nonTIA,
                            index,
                            currentComponent: "index",
                          })
                        }}
                        onRemove={() => {
                          this.setState({ visibleRemoveProperty: true });
                          this.setState({ propertyRemove: nonTIA });
                        }}
                      />
                    );
                  })}
                </>
              )}
            </View>
          }
        >
          <View
            style={{
              position: "fixed",
              right: 120,
              top: 0,
              width: 150,
              zIndex: 9999,
            }}
          >
            <Toast config={toastConfig} />
          </View>
          <View style={{ width: "100%" }}>
            {currentComponent === "index" ? (
              itemSelected ? (
                <PortfolioItem portfolio={itemSelected} />
              ) : (
                <EmptyContent
                  title="Content"
                  subTitle="Please click on your assets under Purchases for details."
                />
              )
            ) : (
              <SearchProperty
                setIsLoading={this.setLoading}
                goBack={() => this.setState({ currentComponent: "index" })}
              />
            )}
          </View>

          {visibleRemoveProperty && (
            <Modal
              size="small"
              btnCancel="Cancel"
              btnSave="Remove"
              disableSave={isLoading}
              open={visibleRemoveProperty}
              onClose={() => this.setState({ visibleRemoveProperty: false })}
              onSave={handleRemoveProperty}
              styleModal={{ width: 343 }}
              styleBtn={{ width: 150 }}
            >
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Image
                  style={{ width: 86, height: 86 }}
                  source={require("../../assets/home/trash.svg")}
                />
                <View style={{ alignItems: "center" }}>
                  <Text
                    style={[
                      styles.menuTitle,
                      {
                        fontSize: 18,
                        marginBottom: 8,
                        marginTop: 6,
                        lineHeight: 27,
                      },
                    ]}
                  >
                    Remove Property
                  </Text>
                  <Text
                    style={[
                      styles.text,
                      {
                        fontSize: FONT_SIZE.large,
                        textAlign: "center",
                        lineHeight: 24,
                      },
                    ]}
                  >
                    Are you sure you want to remove "{propertyRemove?.Suburb}"?
                  </Text>
                </View>
              </View>
            </Modal>
          )}
        </MainLayout>
        {isLoading && <LOADER />}
      </>
    );
  }
}

const styles = StyleSheet.create({
  headerTitle: {
    fontSize: 20,
    lineHeight: 30,
    fontWeight: FONT_WEIGHT.bold,
    color: MAIN_COLORS.BACKGROUND_WHITE,
    fontFamily: MAIN_FONT.semi,
  },

  containerMenu: {
    padding: 12,
    borderRadius: 10,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    gap: 14,
  },

  menuTitle: {
    fontSize: FONT_SIZE.large,
    lineHeight: 24,
    fontWeight: FONT_WEIGHT.bold,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },

  text: {
    fontSize: FONT_SIZE.small,
    fontWeight: FONT_WEIGHT.medium,
    lineHeight: 18,
    color: MAIN_COLORS.G600,
    fontFamily: MAIN_FONT.regular,
  },

  rowCenterBetween: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  btnAddProperty: {
    borderRadius: 6,
    borderWidth: 1,
    borderColor: MAIN_COLORS.PRIMARY_COLOR,
    paddingHorizontal: 12,
    paddingVertical: 4,
    flexDirection: "row",
    gap: 8,
    backgroundColor: "transperent",
  },
});

function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    TIA: state.TIA,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEquity,
      setClient,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
