import React, { memo } from "react";
import {
  Image,
  ScrollView,
  Text,
  View,
} from "react-native";
import fields from "../../../Utility/Fields";
import { MAIN_FONT } from "../../../Utility/Fonts/font";
// import * as WebBrowser from 'expo-web-browser';
import ContactCard from "../../../Utility/ComponentCard/ContactCard/ContactCard2";
import MediaQuery from "react-responsive";
import CheckList from '../../../Utility/ComponentCheckList/indexv2';
import Date from "./Date";

const SideBar = ({
  time,
  files,
  pdfs,
  pngs,
  task,
  clickUpStatusList,
  id,
  checklists,
  solicitorName,
  solicitorPhone,
  solicitorEmail,
  brokerName,
  brokerEmail,
  brokerPhone,
  adminStrationPhone,
  adminStrationAvatar,
  buyerAgentName,
  buyerAgentEmail,
  buyerAgentPhone,
  buyerAgentAvatar,
  headBuyerAgentName,
  headBuyerAgentEmail,
  headBuyerAgentPhone,
  headBuyerAgentAvatar,
  bpInspectorName,
  bpInspectorPhone,
  bpInspectorEmail,
  propertyManagerName,
  propertyManagerEmail,
  propertyManagerPhone,
  quantitySurveyorPhone,
  onCopyPhone,
  thumbnailResult
}) => {

  const transformedFiles = Object.entries(files).map(([title, uri]) => ({ title, uri }));

  return (
    <View>
      {task[fields.appStageName] !== "Market Selection" && task[fields.appStageName] !== "Property Selection" && (
        <View style={{ flexDirection: "column", gap: 16, marginBottom: 22 }}>
          <Date title={"Walk through Inspection Date"} date={time} due={true} />
          <Date title={"Finance Clause Date"} date={time} due={true} />
          <Date title={"Settlement Date"} date={time} due={true} />
        </View>
      )}

      {checklists
        ?.find((item) => item?.task_id === id)
        ?.items?.filter((check) => check?.resolved === true).length &&
        task[fields.appStageName] === "Unconditional" &&
        clickUpStatusList.some(
          (clickUp) =>
            ["settled", "market updates"].includes(
              String(clickUp?.status).toLowerCase()
            ) && clickUp?.taskId === id
        ) ? (
        <CheckList
          checklists={checklists.filter(
            (item) => item?.task_id === id
          )}
        />
      ) : (
        <></>
      )}
      <>
        {!!(
          (task[fields.appStageName] == "Profile Review" &&
            (solicitorName ||
              brokerName ||
              adminStrationPhone)) ||
          (task[fields.appStageName] == "Search Process" &&
            (buyerAgentName ||
              headBuyerAgentName)) ||
          (task[fields.appStageName] ==
            "Contract, Building & Pest and Property Manager" &&
            (bpInspectorName ||
              propertyManagerName)) ||
          (task[fields.appStageName] == "Settlement" &&
            (propertyManagerName ||
              quantitySurveyorPhone))
        ) && (
            <Text
              style={{
                fontSize: 16,
                color: "#FFFFFF",
                fontFamily: MAIN_FONT.semi,
                marginBottom: 16
              }}
            >
              Contacts
            </Text>
          )}
        <MediaQuery minWidth={1281}>
          {(isBreakPoint) => (
            <>
              {task[fields.appStageName] == "Profile Review" ? (
                <div
                  {...(isBreakPoint && {
                    style: {
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "16px",
                      marginBottom: 16
                    },
                  })}
                >
                  {solicitorName &&
                    solicitorEmail &&
                    solicitorPhone && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Legal Partner"}
                          name={solicitorName}
                          phoneNumber={solicitorPhone}
                          email={solicitorEmail}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}

                  {brokerName &&
                    brokerPhone &&
                    brokerEmail && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Financial Partner"}
                          name={brokerName}
                          phoneNumber={brokerPhone}
                          email={brokerEmail}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}

                  {adminStrationPhone && (
                    <View
                      style={{
                        ...(isBreakPoint && {
                          width: "100%",
                        }),
                      }}
                    >
                      <ContactCard
                        type={"Administration Manager"}
                        name={"Taliesha Vukic"}
                        phoneNumber={adminStrationPhone}
                        email={"taliesha@theinvestorsagency.com.au"}
                        employeeAvatar={adminStrationAvatar}
                        onCopyPhone={onCopyPhone}
                      />
                    </View>
                  )}
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </MediaQuery>

        <MediaQuery minWidth={1281}>
          {(isBreakPoint) => (
            <>
              {task[fields.appStageName] == "Search Process" ? (
                <div
                  {...(isBreakPoint && {
                    style: {
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "16px",
                      marginBottom: 16
                    },
                  })}
                >
                  {buyerAgentName &&
                    buyerAgentEmail &&
                    buyerAgentPhone && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Associate Buyers Agent"}
                          name={buyerAgentName}
                          phoneNumber={buyerAgentPhone}
                          email={buyerAgentEmail}
                          employeeAvatar={buyerAgentAvatar}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}

                  {headBuyerAgentName &&
                    headBuyerAgentEmail &&
                    headBuyerAgentPhone && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Head Buyers Agent"}
                          name={headBuyerAgentName}
                          phoneNumber={headBuyerAgentPhone}
                          email={headBuyerAgentEmail}
                          employeeAvatar={
                            headBuyerAgentAvatar
                          }
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </MediaQuery>

        <MediaQuery minWidth={1281}>
          {(isBreakPoint) => (
            <>
              {task[fields.appStageName] ==
                "Contract, Building & Pest and Property Manager" ? (
                <div
                  {...(isBreakPoint && {
                    style: {
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "16px",
                      marginBottom: 16
                    },
                  })}
                >
                  {bpInspectorName &&
                    bpInspectorPhone &&
                    bpInspectorEmail && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Building and Pest Inspector"}
                          name={bpInspectorName}
                          phoneNumber={bpInspectorPhone}
                          email={bpInspectorEmail}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}

                  {propertyManagerName &&
                    propertyManagerEmail &&
                    propertyManagerPhone && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Property Manager"}
                          name={propertyManagerName}
                          phoneNumber={propertyManagerPhone}
                          email={propertyManagerEmail}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </MediaQuery>

        <MediaQuery minWidth={1281}>
          {(isBreakPoint) => (
            <>
              {task[fields.appStageName] == "Settlement" ? (
                <div
                  {...(isBreakPoint && {
                    style: {
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "16px",
                      marginBottom: 16
                    },
                  })}
                >
                  {quantitySurveyorPhone && (
                    <View
                      style={{
                        ...(isBreakPoint && {
                          width: "100%",
                        }),
                      }}
                    >
                      <ContactCard
                        type={"Quantity Surveyor"}
                        name={"Ayden Hassan"}
                        phoneNumber={quantitySurveyorPhone}
                        email={"ayden.hassan@mcgqs.com.au"}
                        onCopyPhone={onCopyPhone}
                      />
                    </View>
                  )}
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </MediaQuery>
      </>

      {pngs.length ? (
        <View style={{ marginBottom: 16 }}>
          <Text
            style={{
              fontSize: 16,
              color: "#FFFFFF",
              fontFamily: MAIN_FONT.semi,
              marginBottom: 16
            }}
          >
            Market Data
          </Text>

          <ScrollView
            horizontal
            style={{
              paddingHorizontal: "0%",
              marginBottom: 20,
              paddingVertical: 10,
              minWidth: "100%",
              zIndex: 999,
            }}
          >
            {pngs.map((item, index) => {
              let ratio = 0.68;
              Image.getSize(item, (width, height) => {
                ratio = height / width;
              });

              return (
                <View
                  style={{
                    backgroundColor: "#FFFFFF",
                    padding: 10,
                    borderRadius: 20,
                    width: 320,
                    marginRight: 20,
                  }}
                >
                  <Image
                    source={{ uri: item }}
                    style={{
                      height: ratio * 300 - 20,
                      width: 300,
                      resizeMode: "contain",
                    }}
                  />
                </View>
              );
            })}
          </ScrollView>
        </View>
      ) : null}

      {transformedFiles.length ? (
        <View style={{ marginBottom: 16 }}>
          <Text
            style={{
              fontSize: 16,
              color: "#FFFFFF",
              fontFamily: MAIN_FONT.semi,
              marginBottom: 16,
            }}
          >
            {task[fields.appStageName] === "Contract, Building & Pest and Property Manager"
              ? "Purchase Contracts"
              : task[fields.appStageName] === "Property Walk-Through"
                ? "Rent Appraisal"
                : "Documents"}
          </Text>

          <ScrollView
            horizontal
            contentContainerStyle={{ minWidth: "100%" }}
            showsHorizontalScrollIndicator={false}
            style={{
              flexDirection: "column",
              paddingHorizontal: 0,
            }}
          >
            <View style={{ flexWrap: "wrap", width: "100%" }}>
              {transformedFiles.map((item, index) =>
                thumbnailResult(item, index)
              )}
            </View>
          </ScrollView>
        </View>
      ) : null}
    </View>
  );
};

export default memo(SideBar);