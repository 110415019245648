import { orderBy } from "lodash";
import { useSelector } from "react-redux";
import React, { memo, useState, useEffect } from "react";
import { View, Text, ActivityIndicator } from "react-native";

//Functions
import { MAIN_COLORS } from "../../../Utility/Colors";
import { getContactByEmail } from "../../../Clickup/api";
import { FONT_SIZE, FONT_WEIGHT, MAIN_FONT } from "../../../Utility/Fonts/font";

//Components
import ContactItem from "../../../Utility/ComponentCard/ContactCard/ContactItem";

const PropertyContacts = ({ portfolio }) => {
  const { opportunities } = useSelector((state) => state.TIA);

  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState([]);

  const getContactQuantitySurvey = async () => {
    const result = await getContactByEmail("ayden.hassan@mcgqs.com.au");
    const tasks = result?.tasks;
    if (tasks.length) {
      const quantitySurveyorPhone = tasks[0].custom_fields.find(
        (field) => field.name === "Phone"
      )?.value;
      return {
        type: "Quantity Surveyor",
        name: "Ayden Hassan",
        phone: quantitySurveyorPhone,
        email: "ayden.hassan@mcgqs.com.au",
        order: 8,
      };
    }
  };

  const getContactHeadBuyerAgent = async (email) => {
    const result = await getContactByEmail(email);
    const tasks = result?.tasks;
    if (tasks.length) {
      const custom_fields = tasks[0].custom_fields;
      const headBuyerAgentName = tasks[0].name;
      const headBuyerAgentEmail = custom_fields.find(
        (field) => field.name === "Email"
      )?.value;
      const headBuyerAgentPhone = custom_fields.find(
        (field) => field.name === "Phone"
      )?.value;

      const avatar = custom_fields.find(
        (field) => field.name === "Contact photo"
      )?.value;
      const headBuyerAgentAvatar = avatar?.length ? avatar[0]?.url : null;
      return {
        type: "Head Buyers Agent",
        name: headBuyerAgentName,
        email: headBuyerAgentEmail,
        phone: headBuyerAgentPhone,
        employeeAvatar: headBuyerAgentAvatar,
        order: 1,
      };
    }
  };

  const getContactAdministration = async () => {
    const result = await getContactByEmail(
      "taliesha@theinvestorsagency.com.au"
    );
    const tasks = result?.tasks;
    if (tasks.length) {
      const custom_fields = tasks[0].custom_fields;
      const adminStrationPhone = custom_fields.find(
        (field) => field.name === "Phone"
      )?.value;
      const avatar = custom_fields.find(
        (field) => field.name === "Contact photo"
      )?.value;
      const adminStrationAvatar = avatar?.length ? avatar[0]?.url : null;
      return {
        type: "Administration Manager",
        phone: adminStrationPhone,
        employeeAvatar: adminStrationAvatar,
        email: "taliesha@theinvestorsagency.com.au",
        name: "Taliesha Vukic",
        order: 2,
      };
    }
  };

  const getContactBuyerAgent = async (email) => {
    if (email) {
      const result = await getContactByEmail(email);
      const tasks = result?.tasks;
      if (tasks.length) {
        const custom_fields = tasks[0].custom_fields;
        const buyerAgentName = tasks[0].name;
        const buyerAgentEmail = custom_fields.find(
          (field) => field.name === "Email"
        )?.value;
        const buyerAgentPhone = custom_fields.find(
          (field) => field.name === "Phone"
        )?.value;

        const avatar = custom_fields.find(
          (field) => field.name === "Contact photo"
        )?.value;
        const buyerAgentAvatar = avatar?.length ? avatar[0]?.url : null;
        return {
          type: "Associate Buyers Agent",
          name: buyerAgentName,
          email: buyerAgentEmail,
          phone: buyerAgentPhone,
          employeeAvatar: buyerAgentAvatar,
          order: 3,
        };
      }
    }
  };

  const fetchData = async () => {
    const item = opportunities.find(
      (one) => one?.["Proposed Property"]?.[0]?.id === portfolio.id
    );

    if (item) {
      const legalPartner = {
        type: "Legal Partner",
        name: item["SOLICITOR'S NAME"],
        phone: item["SOLICITOR'S PHONE"],
        email: item["Solicitor's Email"],
        order: 5,
      };
      const financialPartner = {
        type: "Financial Partner",
        name: item["MORTGAGE BROKER"],
        phone: item["MORTGAGE BROKER PHONE"],
        email: item["MORTGAGE BROKER EMAIL"],
        order: 6,
      };
      const inspector = {
        type: "Building and Pest Inspector",
        name: item["B&P Inspector"],
        phone: item["B&P Inspector Phone"],
        email: item["B&P Inspector Email"],
        order: 7,
      };
      const manager = {
        type: "Property Manager",
        name: item["Property Manager"],
        phone: item["Property Manager Phone"],
        email: item["Property Manager Email"],
        order: 4,
      };

      const promises = [getContactAdministration(), getContactQuantitySurvey()];

      if (item?.assignees?.[0]?.email) {
        promises.push(getContactBuyerAgent(item?.assignees[0]?.email));
      }
      if (item?.["OPPORTUNITY OWNER"]?.[0]?.email) {
        promises.push(
          getContactHeadBuyerAgent(item?.["OPPORTUNITY OWNER"][0]?.email)
        );
      }
      setLoading(true);
      await Promise.all(promises).then((result) => {
        const contacts = orderBy(
          [manager, legalPartner, financialPartner, inspector, ...result],
          ["order", "asc"]
        ).filter((one) => one.name && one.email && one.phone);
        setContacts(contacts);
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <ActivityIndicator size="large" color={MAIN_COLORS.PRIMARY_COLOR} />
      ) : (
        <>
          <Text
            style={{
              fontWeight: FONT_WEIGHT.bold,
              fontSize: FONT_SIZE.large,
              lightHeight: 27,
              color: MAIN_COLORS.TEXT_LIGHT,
              fontFamily: MAIN_FONT.semi,
            }}
          >
            Contacts
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 16,
              flexWrap: "wrap",
            }}
          >
            {contacts.map((contact) => (
              <ContactItem
                style={{
                  flexDirection: "row",
                  borderRadius: 10,
                  padding: 12,
                  gap: 8,
                  backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
                  width: "calc(50% - 8px)",
                }}
                item={contact}
              />
            ))}
          </View>
        </>
      )}
    </>
  );
};

export default memo(PropertyContacts);
