import moment from "moment";
import { BlurView } from "expo-blur";
import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import * as Progress from "react-native-progress";
import { TouchableOpacity, StyleSheet, View, Image, Text } from "react-native";

import { MAIN_COLOR } from "../Utility/Colors";
import { MAIN_FONT } from "../Utility/Fonts/font";

import Time from "./Time";

const OpportunityItem = ({ item, style, coLabelingLogo, onPress }) => {
  const { template } = useSelector((state) => state.TIA);

  const [widthProgress, setWidthProgress] = useState(150);

  let stage = item?.status?.status?.toUpperCase();
  let allStages = Object.keys(template);
  let progress = 0;
  let stageIndex = allStages.indexOf(stage);
  if (stageIndex < 1) progress = 0;
  else progress = (stageIndex * 100) / (allStages.length - 1);

  return (
    <TouchableOpacity style={[styles.container, style]} onPress={onPress}>
      <View style={styles.attached}>
        <Image
          style={{ width: 48, height: 21 }}
          source={require("../assets/home/accountains.svg")}
        />
      </View>
      <Image
        style={{ width: "100%", flex: 1, backgroundColor: "#555" }}
        source={
          coLabelingLogo ||
          (stageIndex > 2
            ? require("../assets/home/portfolio_image_default.png")
            : "")
        }
      />
      <View style={styles.info}>
        <BlurView intensity={10} style={styles.blurInfo}>
          <View style={styles.infoTop}>
            <Text style={styles.portfolioName}>{item?.name}</Text>
            <View
              style={{ flexDirection: "row", alignItems: "center", gap: 6 }}
            >
              <Image
                style={styles.icon}
                source={require("../assets/home/location.svg")}
              />
              {item?.["PROPERTY STREET ADDRESS"] && (
                <Text style={[styles.text]}>
                  {item["PROPERTY STREET ADDRESS"]}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.infoBottom}>
            <View style={styles.rowCenterBetween}>
              <Text style={styles.text}>Update: </Text>
              <Time date={item?.date_updated * 1} />
            </View>
            <View style={styles.rowCenterBetween}>
              <Text style={styles.text}>Progress: </Text>
              <View
                style={{ flex: 1 }}
                onLayout={(event) => {
                  const { width } = event.nativeEvent.layout;
                  setWidthProgress(width);
                }}
              >
                <Progress.Bar
                  progress={progress / 100}
                  animationType="spring"
                  width={widthProgress}
                  height={6}
                  borderRadius={54}
                  borderWidth={0}
                  color="#D3B100"
                  unfilledColor={"#E7ECF2"}
                />
              </View>
              <Text style={styles.textProgress}>
                {progress.toFixed(0) || 0}%
              </Text>
            </View>
          </View>
          <View></View>
        </BlurView>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    width: 380,
    height: 300,
    borderRadius: 12,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR_MAIN,
    overflow: "hidden",
  },

  attached: {
    width: 66,
    height: 33,
    borderRadius: 4,
    backgroundColor: "#FFFFFF99",
    position: "absolute",
    top: 12,
    right: 12,
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 4,
    elevation: 2,
  },

  info: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "auto",
    backgroundColor: "#19232BD9",
  },
  infoTop: { marginBottom: 8 },
  infoBottom: { display: "flex", gap: 6 },
  blurInfo: {
    width: "100%",
    height: "100%",
    paddingHorizontal: 14,
    paddingVertical: 10,
  },
  portfolioName: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: 600,
    color: MAIN_COLOR.BACKGROUND_COLOR,
    marginBottom: 4,
    fontFamily: MAIN_FONT.semi,
  },
  text: {
    fontSize: 12,
    lineHeight: 18,
    fontWeight: 400,
    color: "#C8C8C8",
    fontFamily: MAIN_FONT.regular,
  },

  icon: {
    width: 16,
    height: 16,
  },

  rowCenterBetween: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 6,
  },
  divider: {
    width: 2,
    height: 12,
    backgroundColor: "#C8C8C8",
    borderRadius: 21,
  },

  textProgress: {
    color: "#D3B100",
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 22,
    fontFamily: MAIN_FONT.regular,
  },
});

export default memo(OpportunityItem);
