import { StyleSheet } from "react-native";
import {
  MAIN_COLOR,
  MAIN_COLORS,
  TAB_BAR_COLOR,
} from "../../../../../Utility/Colors";
import {
  FONT_SIZE,
  FONT_WEIGHT,
  MAIN_FONT,
} from "../../../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  container: {
    padding: 16,
    borderWidth: 1,
    borderColor: "#ADB9C74D",
    borderRadius: 8,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    display: "flex",
    flexDirection: "column",
    flexShrink: 1,
    width: "60%",
    gap: 6,
  },
  label: {
    fontWeight: FONT_WEIGHT.bold,
    fontSize: FONT_SIZE.medium,
    lineHeight: 21,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.regular,
  },
  info: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    gap: 17,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  name: {
    fontSize: FONT_SIZE.small,
    fontWeight: FONT_WEIGHT.medium,
    color: MAIN_COLORS.G700,
    fontFamily: MAIN_FONT.regular,
    lineHeight: 16,
  },
  value: {
    fontWeight: FONT_WEIGHT.bold,
    fontSize: FONT_SIZE.small,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.regular,
    lineHeight: 16,
  },
  separator: {
    width: 1,
    backgroundColor: MAIN_COLORS.BORDER_COLOR,
    height: "100%",
    // marginHorizontal: 13,
  },
});
