import { StyleSheet } from "react-native";
import { MAIN_COLORS } from "../../../Utility/Colors";
import { FONT_SIZE, MAIN_FONT } from "../../../Utility/Fonts/font";

const styles = StyleSheet.create({
  titleModal: {
    display: "flex",
    flexDirection: "row",
    gap: 19,
    width: "100%",
    left: 0,
    top: 0,
    height: 127,
  },
  styleBtnClose: {
    position: "absolute",
    right: 0,
    width: 28,
    height: 28,
    top: 0,
  },
  boxThumbnail: {
    width: 250,
    height: "100%",
    position: "relative",
    backgroundColor: "#F5F6FA",
    borderRadius: 8,
  },
  thumbnailIcon: {
    width: 28,
    height: 28,
    position: "absolute",
    top: 8,
    left: 8,
  },
  boxInfoProperty: {
    display: "flex",
    gap: 18,
    height: "100%",
    flex: 1,
    justifyContent: "space-between",
  },
  addressInfo: {
    paddingHorizontal: 0,
    paddingVertical: 0,
    borderWidth: 0,
  },
  flexRowBetween: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 16,
  },
  sectionItemProperty: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    backgroundColor: "#7D888F40",
    padding: 6,
    borderRadius: 5,
    width: 98,
  },
  textItemProperty: {
    fontSize: FONT_SIZE.small,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontWeight: 500,
    fontFamily: MAIN_FONT.regular,
  },
  notData: {
    fontSize: 20,
    textAlign: "center",
    color: MAIN_COLORS.PRIMARY_COLOR,
  },
});

export default styles;
