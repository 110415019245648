import React, { useState, useRef } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import Carousel from "react-native-reanimated-carousel";

const CarouselComp = ({ data, renderItem, visibleItems, listVideos }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [componentWidth, setComponentWidth] = useState(0);
  const carouselRef = useRef(null);
  
  const totalPages = data.length;
  const itemWidth = componentWidth / visibleItems;

  const handlePageChange = (index) => {
    setCurrentIndex(index);
    carouselRef.current?.scrollTo({ index, animated: true });
  };

  return (
    <View
      onLayout={(event) => {
        const { width } = event.nativeEvent.layout;
        setComponentWidth(width);
      }}
    >
      {componentWidth > 0 && (
        <View>
          <Carousel
            ref={carouselRef}
            width={componentWidth}
            height={200}
            data={data}
            scrollAnimationDuration={1500}
            renderItem={({ item }) => (
              <View style={{ flexDirection: "row", height: 200 }}>
                {item.map((video, index) => (
                  <View
                    key={index}
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: itemWidth,
                      height: 200,
                      paddingRight: index < item.length - 1 ? 21 : 0,
                    }}
                  >
                    {renderItem(video, listVideos)}
                  </View>
                ))}
              </View>
            )}
            pagingEnabled
            loop={false}
            mode="horizontal-parallax"
            panGestureHandlerProps={{
              activeOffsetX: [-10, 10],
            }}
            onSnapToItem={(index) => setCurrentIndex(index)}
          />

          {totalPages > 0 && (
            <View style={styles.paginationContainer}>
              {Array.from({ length: totalPages }).map((_, index) => (
                <TouchableOpacity
                  key={index}
                  activeOpacity={1}
                  onPress={() => handlePageChange(index)}
                >
                  <View
                    style={[
                      index === currentIndex ? styles.dotActive : styles.dot,
                    ]}
                  />
                </TouchableOpacity>
              ))}
            </View>
          )}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  paginationContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginVertical: 10,
  },
  dot: {
    width: 4,
    height: 4,
    borderRadius: 16,
    backgroundColor: "#FFFFFF",
    marginHorizontal: 4,
  },
  dotActive: {
    width: 10,
    height: 4,
    borderRadius: 16,
    backgroundColor: "#D3B100",
    marginHorizontal: 4,
  },
});

export default CarouselComp;
