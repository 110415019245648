import React from "react";
import moment from "moment";
import "@firebase/firestore";
import firebase from "firebase";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getImageSize } from "react-image-size";
import * as Progress from "react-native-progress";
import { showMessage } from "react-native-flash-message";
import {
  Text,
  View,
  Image,
  Animated,
  Platform,
  Dimensions,
  SafeAreaView,
  TouchableOpacity,
} from "react-native";

//Function
import { getTime } from "../../Zoho/Tasks";
import { ROLES } from "../../hooks/usePermission";
import { MAIN_COLORS } from "../../Utility/Colors";
import { user as setUser } from "../../Reducer/user";
import { MAIN_FONT } from "../../Utility/Fonts/font";
import { setShowCalendar } from "../../Reducer/portfolio-roadmap";
import { getBrandingByLeadSourceValue, getLeads } from "../../Clickup/api";
import {
  setLoader,
  setOpenDetails,
  setCurrentScreen,
  setOpportunities,
  setNotifications,
  setNotificationsObject,
} from "../../Reducer/tia";

//Component
import Portfolio from "../Folio/index.v2";
import HomeComp from "./components/index.v2";
import { Panel } from "../../Layout/components/Panel";
import ProjectDetails from "../ProjectDetails/index.v2";
import LOADER from "../../Utility/ComponentCard/Loader";
import NotificationsScreen from "../Notifications/indexv2";
import PortfolioRoadmap from "../PortfolioRoadmap/index.v2";
import PortfolioRoadmapOld from "../PortfolioRoadmap";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      today: new Date(),
      monthly: false,
      week: [],
      markedDates: {},
      checklists: [],
      selectedIndex: 0,
      clickUpStatusList: [],
      refreshing: false,
      opacity: new Animated.Value(1),
      currentScreen:
        this.props.TIA.role === "Partner"
          ? "Portfolio Roadmap"
          : this.props.TIA.currentScreen,
      height: new Animated.Value(0),
      email: this.props.TIA.client.email,
      password: null,
      dimensions: {
        window: Dimensions.get("window"),
        screen: Dimensions.get("screen"),
      },
      leads: [],
      notTab: Dimensions.get("window").width > 1024 ? true : false,
      isSmall: Dimensions.get("window").height < 900 ? true : false,
      allBrandingLogo: [],
      mountInHome: false,
      notificationIndex: null,
      version: new URLSearchParams(window.location.search).get("version"),
    };
  }

  setValue = (key, newValue) => {
    this.setState({ [key]: newValue });
  };

  getNextSevenDays = () => {
    var curr = new Date();
    var first = curr.getDate() - curr.getDay();

    let arr = [];
    for (let i = 0; i < 7; i++) arr.push(new Date(curr.setDate(first + i)));

    this.setState({ week: arr, selected: new Date().getDate() });
  };

  componentWillUnmount() {
    this.dimensionsSubscription?.remove();
  }

  componentDidUpdate(prevProp, prevState) {
    if (
      prevState.currentScreen !== "Home" &&
      this.state.currentScreen === "Home"
    ) {
      if (!this.state.mountInHome) {
        this.props.setLoader(true);
        this.refresh();
      }
    }

    if (prevProp.TIA.currentScreen !== this.props.TIA.currentScreen) {
      this.setState({ currentScreen: this.props.TIA.currentScreen });
      this.props.setShowCalendar(true);
    }
  }

  getNotifications() {
    var db = firebase.firestore();
    let allLeadsId = "d22875df-8bfe-430f-8ab9-c33736223f47";
    let leads = this.props.TIA.client.custom_fields?.filter(
      (item) => item.id === allLeadsId
    );
    let leadsIds = {};

    if (leads?.[0]?.value) {
      leads?.[0]?.value.map((item) => {
        leadsIds[item.id] = true;
      });
    }

    db.collection("notifications")
      .get()
      .then((querySnapshot) => {
        let arr = [];
        querySnapshot.forEach((doc) => {
          if (leadsIds[doc.id]) {
            this.props.setNotificationsObject({ id: doc.id, data: doc.data() });
            arr = arr.concat(Object.values(doc.data()));
          }
          arr.sort(function (a, b) {
            return (
              new Date(b.dateUpdated.seconds * 1000) -
              new Date(a.dateUpdated.seconds * 1000)
            );
          });

          this.getMarkedDates(arr);
          this.props.setNotifications(arr);
        });

        db.collection("notifications").onSnapshot((querySnapshot) => {
          arr = [];
          querySnapshot.forEach((doc) => {
            if (leadsIds[doc.id]) {
              this.props.setNotificationsObject({
                id: doc.id,
                data: doc.data(),
              });
              arr = arr.concat(Object.values(doc.data()));
            }
          });
          arr.sort(function (a, b) {
            return (
              new Date(b.dateUpdated.seconds * 1000) -
              new Date(a.dateUpdated.seconds * 1000)
            );
          });
          this.props.setNotifications(arr);
          this.getMarkedDates(arr);
          this.getOpportunities();
        });
      })
      .catch((error) => {
        alert(error.message);
      });
  }

  async getOpportunities() {
    let allLeadsId = "d22875df-8bfe-430f-8ab9-c33736223f47";
    let allLeads = [];
    let leads = this.props.TIA.client.custom_fields?.filter(
      (item) => item.id === allLeadsId
    );

    if (leads?.[0]?.value) {
      leads?.[0]?.value.map((item) => {
        getLeads(item.id)
          .then((result) => {
            const checklists = result?.checklists ?? [];
            this.setState({
              checklists: this.state.checklists.concat([...checklists]),
              clickUpStatusList: this.state.clickUpStatusList.concat([
                { taskId: result?.id, status: result?.status?.status ?? "" },
              ]),
            });
            var object = result?.custom_fields?.reduce(
              (obj, item) => Object.assign(obj, { [item.name]: item.value }),
              {}
            );
            result["custom_fields"] = null;
            allLeads.push({ ...result, ...object });
            if (allLeads.length === leads[0]?.value.length) {
              this.props.setLoader(false);
              this.setState({ refreshing: false, leads: allLeads });

              this.props.setOpportunities(allLeads);

              Promise.all(
                allLeads.map((one) =>
                  getBrandingByLeadSourceValue(one["Lead Source"])
                )
              ).then(async (result) => {
                for (let i = 0; i < result.length; i++) {
                  const item = result[i];
                  const coLabelingLogo = item?.tasks?.[0]?.custom_fields.find(
                    (one) => one.name === "Co-labeling logo"
                  )?.value?.[0].url;
                  if (coLabelingLogo) {
                    const { height, width } = await getImageSize(
                      coLabelingLogo
                    );
                    const fixedWidth =
                      (40 * width) / height > 70 ? 70 : (40 * width) / height;
                    item.fixedWidth = fixedWidth;
                  }
                }
                this.setState({ allBrandingLogo: result });
              });
            }
          })
          .catch((error) => {
            this.props.setLoader(false);
            alert(error.message);
            showMessage({
              message: error.message,
              type: "info",
              color: "#FFF",
              backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
            });
          });
      });
    } else {
      this.props.setLoader(false);
    }
  }

  async refresh() {
    this.getOpportunities();
    this.getNotifications();
  }

  onChange = ({ window, screen }) => {
    this.setState({ dimensions: { window, screen } });
  };

  componentDidMount() {
    const { role } = this.props.TIA;
    Dimensions.addEventListener("change", ({ window }) => {
      this.setState({
        notTab: window.width > 1024 ? true : false,
        isSmall: window.height < 900 ? true : false,
      });
    });

    this.dimensionsSubscription = Dimensions.addEventListener(
      "change",
      this.onChange
    );

    this.getNextSevenDays();
    if (role.toLowerCase() === ROLES.CLIENT) {
      if (this.state.currentScreen === "Home") {
        this.props.setLoader(true);
        this.refresh();
        this.setState({ mountInHome: true });
      }
    } else {
      this.setState({ currentScreen: "PortfolioRoadmap" });
    }

    this.props.setOpenDetails("");

    const search = window.location.search;
    const params = new URLSearchParams(search);
    if (window.location.pathname === "/verify-email" && params.get("email")) {
      this.props.navigation.navigate("SignUpScreen");
    }
    if (
      window.location.pathname.indexOf("scenario-detail") !== -1 &&
      params.get("scenario_id")
    ) {
      this.setState({ currentScreen: "PortfolioRoadmap" });
    }
  }

  renderFlatListItem(item, index) {
    let stage = item?.status?.status?.toUpperCase();
    const coLabelingLogo = this.state.allBrandingLogo[
      index
    ]?.tasks?.[0]?.custom_fields.find((one) => one.name === "Co-labeling logo")
      ?.value?.[0].url;
    const fixedWidth = this.state.allBrandingLogo[index]?.fixedWidth || 70;
    let allStages = Object.keys(this.props.TIA.template);

    let progress = 0;
    let stageIndex = allStages.indexOf(stage);

    if (stageIndex < 1) {
      progress = 0;
    } else {
      progress = (stageIndex * 100) / (allStages.length - 1);
    }

    return (
      <TouchableOpacity
        onPress={() =>
          this.setState({
            currentScreen: "ProjectDetails",
            selectedIndex: index,
          })
        }
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: 10,
          padding: 25,
          width: 360,
          marginBottom: 20,
          marginRight: 20,
        }}
      >
        <View style={{ display: "flex", flexDirection: "row", gap: 10 }}>
          <View style={{ flexShrink: 1, width: "100%" }}>
            {coLabelingLogo ? (
              <View style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                <View style={{ minHeight: 54, flex: 2, marginBottom: 5 }}>
                  <Text
                    style={{
                      fontSize: 17,
                      color: "#36414C",
                      fontFamily: MAIN_FONT.semi,
                    }}
                  >
                    {item.name}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  {!!coLabelingLogo && (
                    <Image
                      style={{
                        height: 40,
                        width: fixedWidth,
                      }}
                      resizeMode="contain"
                      source={coLabelingLogo}
                    />
                  )}
                </View>
              </View>
            ) : (
              <View style={{ minHeight: 54, flex: 2, marginBottom: 5 }}>
                <Text
                  style={{
                    fontSize: 17,
                    color: "#36414C",
                    fontFamily: MAIN_FONT.semi,
                  }}
                >
                  {item.name}
                </Text>
              </View>
            )}

            <Text
              style={{
                fontSize: 14,
                color: "#66737F",
                fontFamily: MAIN_FONT.regular,
                marginBottom: 5,
              }}
            >
              Last Update :
            </Text>

            <Text
              style={{
                fontSize: 14,
                color: "#66737F",
                fontFamily: MAIN_FONT.regular,
                marginBottom: 5,
              }}
            >
              {getTime(item.date_updated * 1)} -{" "}
              {moment(item.date_updated * 1).format("DD/MM/YYYY")}
            </Text>
          </View>
        </View>

        <View
          style={{
            height: 1,
            backgroundColor: "#E7ECF2",
            width: "100%",
            marginVertical: 5,
          }}
        />

        <View style={{ justifyContent: "space-between", flexDirection: "row" }}>
          <Text
            style={{
              fontSize: 14,
              color: "#66737F",
              fontFamily: MAIN_FONT.regular,
              marginVertical: 5,
            }}
          >
            Project Progress:
          </Text>
          <Text
            style={{
              fontSize: 14,
              color: "#36414C",
              fontFamily: MAIN_FONT.regular,
              marginVertical: 5,
            }}
          >
            {progress.toFixed(0)}%
          </Text>
        </View>

        <Progress.Bar
          progress={progress / 100}
          animationType="spring"
          width={310}
          height={15}
          borderRadius={10}
          borderColor={"transparent"}
          color={MAIN_COLORS.PRIMARY_COLOR}
          unfilledColor={"#E7ECF2"}
        />
      </TouchableOpacity>
    );
  }

  aggregateByDate(obj) {
    const result = {};

    for (const key in obj) {
      const date = obj[key].date;

      if (result[date]) {
        result[date].quantity += 1;
      } else {
        result[date] = { ...obj[key], quantity: 1 };
      }
    }

    return result;
  }

  getMarkedDates(notifications) {
    let markedDates = {};
    notifications.map((item, index) => {
      markedDates[index] = {
        taskId: item.taskId,
        statusName: item.statusName,
        date: moment(item.dateUpdated.seconds * 1000).format("YYYY-MM-DD"),
      };
    });
    const result = this.aggregateByDate(markedDates);

    this.setState({ markedDates: result });
  }

  render() {
    const { markedDates, notTab, version } = this.state;

    const goToProjectDetails = (id, statusName) => {
      let arr = [
        // "PROFILE REVIEW",
        "SEARCH PROCESS",
        "MARKET SELECTION",
        "PROPERTY SELECTION",
        "PROPERTY WALK-THROUGH",
        "CONTRACT, B&P & PM",
        "BUILDING & PEST REPORTS",
        "UNCONDITIONAL",
        "SETTLED",
        "MARKET UPDATES",
      ];

      this.props.TIA.opportunities.map((item, index) => {
        if (item.id == id) {
          this.setState({
            currentScreen: "ProjectDetails",
            selectedIndex: index,
            notificationIndex: arr.indexOf(statusName),
          });
        }
      });
    };

    return (
      <SafeAreaView
        style={{
          flex: 1,
          flexDirection: notTab ? "row" : "column",
          backgroundColor: "#FFFFFF",
        }}
        keyboardShouldPersistTaps={true}
        behavior={Platform.OS === "ios" ? "padding" : "padding"}
        keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}
      >
        <Panel
          screen={this.state.currentScreen}
          setPropScreen={this.props.setCurrentScreen}
          setStateScreen={(screen) => this.setState({ currentScreen: screen })}
          navigate={this.props.navigation.navigate}
        />

        {this.state.currentScreen === "Home" && (
          <HomeComp
            leads={this.state.leads}
            goToProjectDetails={goToProjectDetails}
            allBrandingLogo={this.state.allBrandingLogo}
            setStateScreen={(screen) =>
              this.setState({ currentScreen: screen })
            }
            setStateSelectedIndex={(selectedIndex) =>
              this.setState({ selectedIndex: selectedIndex })
            }
            markedDates={markedDates}
          />
        )}
        {this.state.currentScreen === "ProjectDetails" && (
          <ProjectDetails
            deal={this.state.leads[this.state.selectedIndex]}
            notificationIndex={this.state.notificationIndex}
            checklists={this.state.checklists}
            clickUpStatusList={this.state.clickUpStatusList}
            setStateScreen={(screen) =>
              this.setState({ currentScreen: screen })
            }
          />
        )}
        {this.state.currentScreen === "Portfolio" && <Portfolio />}
        {this.state.currentScreen === "Notifications" && (
          <NotificationsScreen
            goToProjectDetails={goToProjectDetails}
            markedDates={markedDates}
          />
        )}
        {this.state.currentScreen === "Portfolio Roadmap" &&
          (version === "old" ? <PortfolioRoadmapOld /> : <PortfolioRoadmap />)}
        {this.props.TIA.loader ? <LOADER /> : null}
      </SafeAreaView>
    );
  }
}

function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    TIA: state.TIA,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setOpportunities,
      setNotifications,
      setNotificationsObject,
      setLoader,
      setUser,
      setOpenDetails,
      setCurrentScreen,
      setShowCalendar,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
