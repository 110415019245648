import { BlurView } from "expo-blur";
import React from "react";
import {
  Image,
  Modal,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Dimensions,
  View,
} from "react-native";
import { MAIN_COLORS } from "../Utility/Colors";
import { FONT_SIZE, FONT_WEIGHT, MAIN_FONT } from "../Utility/Fonts/font";

const ModalBase = ({
  open,
  title,
  children,
  btnSave,
  btnCancel,
  disableSave,
  styleBtnClose,
  onSave,
  onClose,
  styleModal,
  styleBtn,
  size = "medium",
  sizeBtnClose,
  closePrimary = false,
}) => {
  const { height } = Dimensions.get("window");
  return (
    <Modal
      animationType="fade"
      transparent
      visible={open}
      presentationStyle="formSheet"
    >
      <BlurView
        tint="dark"
        intensity={12}
        style={{ position: "absolute", height: "100%", width: "100%" }}
      >
        <View
          style={{
            background: "rgba(0, 0, 0, 0.58)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <View
            style={[
              {
                backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
                borderRadius: 10,
                paddingHorizontal: 16,
                paddingVertical: 16,
                width: size === "small" ? 525 : size === "medium" ? 654 : 800,
                maxHeight: "95vh",
                boxShadow: "4px 4px 30px 0px rgba(211, 177, 0, 0.1)",
                gap: 22,
              },
              styleModal,
            ]}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: 20,
                  fontFamily: MAIN_FONT.semi,
                  color: MAIN_COLORS.TEXT_LIGHT,
                  flex: 1,
                }}
              >
                {title}
              </Text>
              <TouchableOpacity style={[styleBtnClose]} onPress={onClose}>
                <Image
                  resizeMode="contain"
                  style={[{ width: 12, height: 12, margin: "auto" }, sizeBtnClose]}
                  source={closePrimary ? require("../assets/home/close_primary.svg") : require("../assets/home/close-modal.svg")}
                />
              </TouchableOpacity>
            </View>
            {children}
            <View style={styles.btnWrap}>
              <TouchableOpacity
                style={[styles.btn, styles.btnCancel, styleBtn]}
                onPress={onClose}
              >
                <Text style={styles.btnText}>{btnCancel}</Text>
              </TouchableOpacity>
              <div id="btn_add_scenario">
                <TouchableOpacity
                  disabled={disableSave}
                  style={[
                    styles.btn,
                    styles.btnSave,
                    disableSave && { backgroundColor: MAIN_COLORS.BORDER_COLOR },
                    styleBtn,
                  ]}
                  onPress={onSave}
                >
                  <Text
                    style={[
                      styles.btnText,
                      {
                        color: disableSave
                          ? MAIN_COLORS.G600
                          : MAIN_COLORS.BACKGROUND_MAIN,
                      },
                    ]}
                  >
                    {btnSave}
                  </Text>
                </TouchableOpacity>
              </div>
            </View>
          </View>
        </View>
      </BlurView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  btn: {
    width: 170,
    height: 46,
    paddingHorizontal: 24,
    paddingVertical: 10,
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  btnWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 12,
  },
  btnCancel: {
    borderColor: MAIN_COLORS.TEXT_GRAY,
    borderWidth: 1.5,
  },
  btnSave: {
    backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
  },
  btnText: {
    fontSize: FONT_SIZE.medium,
    fontWeight: FONT_WEIGHT.bold,
    fontFamily: MAIN_FONT.regular,
    lightHeight: 21,
    color: MAIN_COLORS.G600,
  },
});
export default ModalBase;
