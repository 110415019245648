import React, { memo, useEffect, useState } from "react";
import { LinearGradient } from "expo-linear-gradient";
import {
  View,
  StyleSheet,
  Text,
  Linking,
  TouchableOpacity,
} from "react-native";

// Function
import { MAIN_COLOR, MAIN_COLORS } from "../../../Utility/Colors";
import { MAIN_FONT } from "../../../Utility/Fonts/font";

// Component
import Time from "../../../components/Time";
import fields from "../../../Utility/Fields";

const UpdateComp = ({ title, task, time }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleSeeMoreClick = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (task[fields.appStageName] === "Unconditional") {
      setIsExpanded(true);
    }
  }, [task]);

  return (
    <View style={[styles.container, isExpanded ? styles.expanded : {}]}>
      {!isExpanded ? (
        <LinearGradient
          colors={["rgba(25, 35, 43, 0)", "#19232B"]}
          style={styles.afterElement}
          start={{ x: 0, y: 0.13 }}
          end={{ x: 0, y: 0.765 }}
        />
      ) : null}

      {task[fields.appStageName] !== "Unconditional" && (
        <View style={styles.seeMoreWrap}>
          <Text style={styles.textSeeMore} onPress={handleSeeMoreClick}>
            {isExpanded ? "See less" : "See more"}
          </Text>
        </View>
      )}

      <Text style={styles.title}>{title}</Text>
      <View style={styles.rowCenterBetween}>
        <Text style={styles.textUpdate}>Update: </Text>
        <Time date={time} />
      </View>
      <Text style={styles.textContent}>
        {title === "Building & Pest Notes"
          ? task?.["2 minute update"]
              ?.replace(/\t/g, " ")
              .replace(/ +/g, " ")
              .trim()
          : task?.["Progress"]?.replace(/\t/g, " ").replace(/ +/g, " ").trim()}
      </Text>
      {task.status === "Complete" ? (
        <>
          <Autolink
            text={"\n" + task?.["Progress"]}
            email
            hashtag="instagram"
            mention="twitter"
            phone="phone"
            url
            style={styles.textContent}
            matchers={[
              { ...MyLatLngMatcher },
              {
                pattern: "Google Review",
                onPress: () =>
                  Linking.openURL(
                    "https://www.google.com/search?q=google+reviews+the+investors+agency&rlz=1C1CHBF_en-GBAU956AU956&sxsrf=AOaemvIGhM8X6XVjlpD_3Ftg1MALtrOXmw%3A1638336474356&ei=2genYdmOFene1sQP7NCGuAM&ved=0ahUKEwjZ48387sH0AhVpr5UCHWyoATcQ4dUDCA8&uact=5&oq=google+reviews+the+investors+agency&gs_lcp=Cgdnd3Mtd2l6EAM6BwgAEEcQsAM6BwgAELADEEM6EAguEMcBENEDEMgDELADEEM6EAguEMcBEKMCEMgDELADEEM6BQgAEIAEOgQIABBDOgYIABAWEB46CAgAEBYQChAeOggIIRAWEB0QHjoHCCEQChCgAUoECEEYAFD6BljfMWCpNWgDcAJ4AIABlQKIAaAhkgEGMC4xOC41mAEAoAEByAEOwAEB&sclient=gws-wiz#lrd=0x6b0d558af53f0915:0x7fc4825f5701c315,1,,,"
                  ),
              },
              {
                pattern: "Facebook Review",
                onPress: () =>
                  Linking.openURL(
                    "https://www.facebook.com/theinvestorsagency/reviews/?ref=page_internal"
                  ),
              },
            ]}
          />

          {task["Facebook Review"] && (
            <TouchableOpacity
              onPress={() => Linking.openURL(task["Facebook Review"])}
              style={styles.btnLinking}
            >
              <Text style={styles.textLinking}>Facebook Review</Text>
            </TouchableOpacity>
          )}

          {task["Google Review"] && (
            <TouchableOpacity
              onPress={() => Linking.openURL(task["Google Review"])}
              style={styles.btnLinking}
            >
              <Text style={styles.textLinking}>Google Review</Text>
            </TouchableOpacity>
          )}

          {task["Review"] && (
            <TouchableOpacity
              onPress={() => Linking.openURL(task["Review"])}
              style={styles.btnLinking}
            >
              <Text style={styles.textLinking}>Review</Text>
            </TouchableOpacity>
          )}
        </>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    padding: 12,
    paddingBottom: 24,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR_MAIN,
    flex: 1,
    borderRadius: 12,
    gap: 8,
    display: "flex",
    maxHeight: 290,
    overflow: "hidden",
  },

  expanded: {
    maxHeight: "fit-content",
  },

  afterElement: {
    position: "absolute",
    bottom: 38,
    right: 0,
    width: "100%",
    height: 60,
    zIndex: 1,
  },

  title: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 24,
    color: MAIN_COLOR.BACKGROUND_COLOR,
    fontFamily: MAIN_FONT.semi,
  },

  textUpdate: {
    fontSize: 14,
    lineHeight: 20,
    fontWeight: 400,
    color: MAIN_COLOR.BACKGROUND_COLOR,
    fontFamily: MAIN_FONT.regular,
  },

  rowCenterBetween: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 6,
  },

  seeMoreWrap: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "100%",
    height: 38,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    borderRadius: 12,
  },

  textSeeMore: {
    textAlign: "center",
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 18,
    color: "#D3B100",
    textDecorationLine: "underline",
    cursor: "pointer",
    fontFamily: MAIN_FONT.regular,
  },

  textContent: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 21,
    textAlign: "justify",
    color: MAIN_COLOR.G600,
    paddingBottom: 12,
    fontFamily: MAIN_FONT.regular,
  },

  btnLinking: {
    flexDirection: "row",
    backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
    borderRadius: 10,
    padding: 12.5,
    width: 300,
    justifyContent: "center",
    alignSelf: "center",
    marginVertical: 10,
  },
  textLinking: {
    color: "#FFFFFF",
    fontFamily: MAIN_FONT.semi,
    fontSize: 14,
    textAlign: "center",
  },
});

export default memo(UpdateComp);
