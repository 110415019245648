import React from "react";
import { View, Image, Text, Dimensions } from "react-native";

import styles from "./style";

const AuthBanner = () => {
  const isPc = Dimensions.get("window").width > 1000 ? true : false;
  const isSmall = Dimensions.get("window").height < 900 ? true : false;

  return (
    <View
      style={{
        overflow: "hidden",
        width: isPc ? "50%" : "100%",
        display: !isPc && "none",
      }}
    >
      <View style={styles.boxLogoTIA}>
        <Image
          style={{ height: 57, width: 179 }}
          resizeMode="contain"
          source={require("../../assets/home/logo.svg")}
        />
      </View>

      <View style={styles.sectionContent}>
        <View>
          <Text style={styles.sectionTitle}>The Investors Agency</Text>
          <Text style={styles.sectionSubTitle}>
            You’re on your way to better property management.
          </Text>
        </View>

        <View
          style={{
            flex: 1,
            width: "90%",
            marginHorizontal: "auto",
          }}
        >
          <Image
            style={{ width: "100%", height: "100%" }}
            resizeMode="contain"
            source={require("../../assets/home/pc.svg")}
          />
        </View>

        <Text style={[styles.sectionSubTitle, { paddingBottom: 24 }]}>
          Copyright 2022 The Investors Agency Ptv Ltd
        </Text>
      </View>
    </View>
  );
};

export default AuthBanner;
