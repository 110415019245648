import {
  NOTIFICATIONS,
  OPPORTUNITIES,
  PROPERTIES,
  CLIENT,
  TEMPLATE,
  DETAILS,
} from "../Utility/ReduxType";
import { setDataToAsyncStorage } from "../Action/asyncCtrl";

export const setOpportunities = (content) => ({
  type: OPPORTUNITIES.SET_OPPORTUNITIES,
  payload: content,
});

export const setLoader = (content) => ({
  type: "LOADER",
  payload: content,
});

export const setEquity = (content) => ({
  type: "EQUITY",
  payload: content,
});

export const setNotifications = (content) => ({
  type: NOTIFICATIONS.SET_NOTIFICATIONS,
  payload: content,
});

export const setNotificationsObject = (content) => ({
  type: NOTIFICATIONS.SET_NOTIFICATIONS_OBJECT,
  payload: content,
});

export const setProperties = (content) => ({
  type: PROPERTIES.SET_PROPERTIES,
  payload: content,
});

export const setClient = (content) => ({
  type: CLIENT.SET_CLIENT,
  payload: content,
});

export const reset = (content) => ({
  type: "RESET",
  payload: content,
});

export const setTemplate = (content) => ({
  type: TEMPLATE.SET_TEMPLATE,
  payload: content,
});

export const setOpenDetails = (content) => ({
  type: DETAILS.SET_OPEN,
  payload: content,
});

export const setCurrentScreen = (content) => ({
  type: "SCREEN",
  payload: content,
});

const initialState = {
  opportunities: [],
  notifications: [],
  properties: [],
  client: [],
  notificationObjects: {},
  template: {},
  loader: false,
  equity: 0,
  detailsOpen: "",
  role: "Client",
  currentScreen: "Home",
};

export const tia = (state = initialState, action) => {
  const {
    notifications,
    opportunities,
    properties,
    notificationObjects,
    template,
    loader,
  } = state;

  switch (action.type) {
    case CLIENT.SET_CLIENT: {
      return {
        ...state,
        client: action.payload,
        role: action.payload.role === "partner" ? "Partner" : "Client",
      };
      // return Object.assign({}, state, {client: action.payload});
    }

    case NOTIFICATIONS.SET_NOTIFICATIONS: {
      // alert('Notifications: '+JSON.stringify(state));
      return Object.assign({}, state, { notifications: action.payload });
    }

    case NOTIFICATIONS.SET_NOTIFICATIONS_OBJECT: {
      return Object.assign({}, state, {
        notificationObjects: {
          ...notificationObjects,
          [action.payload.id]: action.payload.data,
        },
      });
    }

    case OPPORTUNITIES.SET_OPPORTUNITIES: {
      let sorted = action.payload.sort(function (a, b) {
        return new Date(b.date_updated * 1) - new Date(a.date_updated * 1);
      });

      return Object.assign({}, state, { opportunities: sorted });
    }

    case "LOADER": {
      return Object.assign({}, state, { loader: action.payload });
    }

    case "EQUITY": {
      return Object.assign({}, state, { equity: action.payload });
    }

    case TEMPLATE.SET_TEMPLATE: {
      return Object.assign({}, state, { template: action.payload });
    }

    case "RESET": {
      return Object.assign({}, state, {
        opportunities: [],
        notifications: [],
        properties: [],
        client: [],
        notificationObjects: {},
      });
    }

    case PROPERTIES.SET_PROPERTIES: {
      return {
        type: action.type,
        payload: action.payload,
        ...state,
        properties: action.payload,
      };
    }

    case DETAILS.SET_OPEN: {
      return {
        ...state,
        detailsOpen: action.payload,
      };
    }

    case "SCREEN": {
      return {
        ...state,
        currentScreen: action.payload,
      };
    }

    default:
      return state;
  }
};
