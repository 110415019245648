import "chart.js/auto";
import "chartjs-adapter-moment";
import { BlurView } from "expo-blur";
import { Line } from "react-chartjs-2";
import React, { memo, useEffect, useRef } from "react";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import {
  View,
  StyleSheet,
  ScrollView,
  Text,
  TextInput,
  ImageBackground,
} from "react-native";

//Functions
import {
  FONT_SIZE,
  FONT_WEIGHT,
  MAIN_FONT,
} from "../../../Utility/Fonts/font.js";
import {
  formatDollar,
  externalTooltipHandlerNew,
} from "../../../Utility/common.js";
import { styles } from "../index.v2";
import { MAIN_COLORS } from "../../../Utility/Colors";

import FlastList from "../../../components/FlastList.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const data = [
  [
    { x: "Oct", y: 2145 },
    { x: "Jan", y: 532 },
    { x: "July", y: 2133 },
    { x: "Dec", y: 21 },
  ],
  [
    { x: "Oct", y: 2145 },
    { x: "Jan", y: 532 },
    { x: "July", y: 2133 },
    { x: "Dec", y: 21 },
  ],
  [
    { x: "Oct", y: 2145 },
    { x: "Jan", y: 532 },
    { x: "July", y: 2133 },
    { x: "Dec", y: 21 },
  ],
  [
    { x: "Oct", y: 2145 },
    { x: "Jan", y: 532 },
    { x: "July", y: 2133 },
    { x: "Dec", y: 21 },
  ],
  [
    { x: "Oct", y: 2145 },
    { x: "Jan", y: 532 },
    { x: "July", y: 2133 },
    { x: "Dec", y: 21 },
  ],
  [
    { x: "Oct", y: 2145 },
    { x: "Jan", y: 532 },
    { x: "July", y: 2133 },
    { x: "Dec", y: 21 },
  ],
];

const PropertyFormatData = () => {
  return (
    <View style={_styles.gap}>
      <View style={_styles.rowGap16}>
        <View style={{ flex: 2, borderRadius: 10, overflow: "hidden" }}>
          <ImageBackground
            style={_styles.backgroudImage}
            source={{
              uri: "https://s3-alpha-sig.figma.com/img/ce99/d122/3c1e6ea9d6d5b9d84521cccd09a1d44e?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=pwmBRlt86D0pMmqvgMyt8JUQGtM~kwVl7BGweQ01cIreq0ZCfKEz0y3lmBdh0~bSYgQso6I0g8l9A~K1hYT2BMWgOGcQzJNuC~dRohMDEX2aESFsqW2lvHU87U96JtTgK4OFkl-unthMfmlGagrYjjECZbPIkjOaGpMu5X-ePpvQgIo~wMNhW8WiUUyZfYluWuAaNOntTzqCuTNj19U-15CtVakL8JS8MV8HCAv2-YEYwIXIOJDuPg5xMzYuAfuWBZM2B5YW~H701ZV3ShlOcKpR7nSXLqMPBJBg2~8-lcrXeaBWN-m2Y0-E2rbW~-D01k~SdZ-lAKDAYXj4VTC9mQ__",
            }}
          >
            <View>
              <Text style={styles.subTitleSection}>4 Bed house medians</Text>
              <Text style={_styles.thumnailSubTitle}>
                The average costs associated.
              </Text>
            </View>
            <View style={{ flexDirection: "row", gap: 12 }}>
              <View style={{ gap: 8, flex: 1 }}>
                <Text style={_styles.thumbnailLabelInput}>
                  Medain 4 Bed Price
                </Text>
                <TextInput value="$580.000" style={_styles.thumbnailInput} />
              </View>
              <View style={{ gap: 8, flex: 1 }}>
                <Text style={_styles.thumbnailLabelInput}>
                  Medain 4 Bed Rent
                </Text>
                <TextInput value="$580.000" style={_styles.thumbnailInput} />
              </View>
              <View style={{ gap: 8, flex: 1 }}>
                <Text style={_styles.thumbnailLabelInput}>
                  4 Bed Vacancy Rate
                </Text>
                <TextInput value="$580.000" style={_styles.thumbnailInput} />
              </View>
            </View>
            <View style={_styles.backgroudImageB}>
              <BlurView
                tint="dark"
                intensity={7}
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                }}
              />
            </View>
          </ImageBackground>
        </View>
        <View style={{ flex: 1, gap: 22 }}>
          <View>
            <Text style={_styles.titleDeposit}>Buy cost at 12% deposit</Text>
            <Text style={styles.textSection}>Deposit + Buying cost</Text>
            <TextInput value="$58,140" style={_styles.inputDeposit} />
          </View>
          <View>
            <Text style={_styles.titleDeposit}>Buy cost at 20% deposit</Text>
            <Text style={styles.textSection}>Deposit + Buying cost</Text>
            <TextInput value="$115,140" style={_styles.inputDeposit} />
          </View>
        </View>
        <View style={{ flex: 1, gap: 22 }}>
          <View>
            <Text style={_styles.titleDeposit}>Hold cost at 12% deposit</Text>
            <Text style={styles.textSection}>Loanpayments</Text>
            <TextInput value="$58,140" style={_styles.inputDeposit} />
          </View>
          <View>
            <Text style={_styles.titleDeposit}>Hold cost at 20% deposit</Text>
            <Text style={styles.textSection}>Loanpayments</Text>
            <TextInput value="$115,140" style={_styles.inputDeposit} />
          </View>
        </View>
      </View>
      <FlastList
        width={368 + 16}
        height={386}
        paginate
        data={data}
        renderItem={({ item, index }) => {
          return (
            <View style={_styles.chartItem} key={index}>
              <View style={_styles.headerChart}>
                <View style={_styles.headerDivider} />

                <View style={_styles.headerCenter}>
                  <Text style={_styles.headerCenterText}>
                    Historical Price growth
                  </Text>
                </View>
                <View style={_styles.headerDivider} />
              </View>
              <RenderChart width={"100%"} height={300} result={item} />
              <View style={_styles.footerChart}>
                <View style={_styles.footerChartDot} />
                <Text style={_styles.footerChartText}>4 Bedroom House</Text>
              </View>
            </View>
          );
        }}
      />
    </View>
  );
};

export const RenderChart = ({ width, height, result = [] }) => {
  const chartRef = useRef(null);

  const minY = Math.min(...result?.map(({ y }) => y));
  const maxY = Math.max(...result?.map(({ y }) => y));

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    hover: { mode: "index", intersect: false },
    tooltips: { mode: "index", intersect: false },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
        position: "nearest",
        cornerRadius: 20,
        callbacks: {
          label: (value) => formatDollar(value.raw.y),
        },
        external: (context) =>
          externalTooltipHandlerNew(
            context,
            { left: 8, right: 8, top: 0, bottom: 0 },
            "46px"
          ),
      },
    },
    scales: {
      x: {
        font: { family: MAIN_FONT.regular, size: 12 },
        grid: { display: false },
        border: { color: "#3F4448" },
        ticks: {
          callback: (label, index) => result[index].x,
        },
      },
      y: {
        border: { dash: [4, 4], color: "#3F4448" },
        grid: {
          drawTicks: false,
          color: function (context) {
            if (context.index === 3) return "rgba(0,0,0,0)";
            return "#3F4448";
          },
        },
        beginAtZero: false,
        min: minY,
        max: maxY + maxY * 0.1,
        ticks: {
          display: false,
          maxTicksLimit: 5,
          stepSize: (maxY + maxY * 0.1 - minY) / 5,
        },
      },
      y1: {
        stacked: true,
        position: "right",
        grid: { display: false },
        ticks: { display: false },
        border: { color: "#3F4448" },
      },
    },
  };

  const data = {
    datasets: [
      {
        data: result,
        backgroundColor: (context) => {
          const bgColor = [
            "rgba(250, 191, 1, 0.3)",
            "rgba(250, 191, 1, 0.15)",
            "rgba(250, 191, 1, 0.05)",
          ];
          if (!context.chart.chartArea) return;
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0.1, bgColor[0]);
          gradientBg.addColorStop(0.5, bgColor[1]);
          gradientBg.addColorStop(1, bgColor[2]);
          return gradientBg;
        },
        borderColor: MAIN_COLORS.PRIMARY_COLOR,
        pointBorderWidth: 5.5,
        pointBackgroundColor: "#D3B100",
        pointRadius: ({ index }) => 0,
        pointHoverRadius: 6,
        lineTension: 0.5,
        borderWidth: 1,
        fill: true,
        spanGaps: true,
        clip: {
          left: false,
          right: false,
          top: false,
          bottom: false,
        },
      },
    ],
  };

  const plugins = {
    afterDraw: function (chart, easing) {
      if (chart?.tooltip?._active && chart?.tooltip?._active?.length) {
        const activePoint = chart.tooltip._active[0];
        const ctx = chart.ctx;
        const x = activePoint.element.x;
        const topY = chart.scales.y.chart.tooltip.caretY + 10;
        const bottomY = chart.scales.y.bottom;

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 1;
        ctx.strokeStyle = MAIN_COLORS.PRIMARY_COLOR;
        ctx.stroke();
        ctx.restore();
      }
    },
  };

  useEffect(() => {
    const chart = chartRef.current;
    chart?.tooltip?.setActiveElements([]);
    chart?.update();
  }, []);

  return (
    <View style={{ width: width, height: height }}>
      <Line
        options={options}
        style={{ marginTop: 12, marginBottom: 12 }}
        data={data}
        ref={chartRef}
        plugins={[plugins]}
      />
    </View>
  );
};

const _styles = StyleSheet.create({
  rowGap16: {
    flexDirection: "row",
    gap: 16,
  },
  gap: { gap: 16 },

  chartItem: {
    borderWidth: 1,
    borderColor: "#4B555D99",
    borderRadius: 10,
    width: 368,
    paddingTop: 12,
    paddingBottom: 22,
    paddingHorizontal: 4,
  },

  footerChart: {
    flexDirection: "row",
    gap: 6,
    alignItems: "center",
    justifyContent: "center",
  },

  footerChartDot: {
    width: 9,
    height: 9,
    backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
    borderRadius: 4.5,
  },

  footerChartText: {
    fontSize: 10,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontWeight: 400,
    lineHeight: 15,
    fontFamily: MAIN_FONT.regular,
  },

  headerChart: {
    flexDirection: "row",
    gap: 12,
    alignItems: "center",
    paddingHorizontal: 13,
  },

  headerDivider: {
    height: 1,
    flex: 1,
    backgroundColor: "#4B555D99",
  },

  headerCenter: {
    paddingHorizontal: 16,
    paddingVertical: 6,
    borderWidth: 1,
    backgroundColor: "#19232B80",
    borderColor: "#4B555D99",
    borderRadius: 36,
  },

  headerCenterText: {
    lineHeight: 21,
    fontSize: FONT_SIZE.medium,
    fontWeight: FONT_WEIGHT.bold,
    color: "#D3B100",
    fontFamily: MAIN_FONT.regular,
  },

  backgroudImage: {
    width: "100%",
    height: "100%",
    resizeMode: "cover",
    padding: 22,
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
  },

  backgroudImageB: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.45)",
    zIndex: -1,
  },

  thumnailSubTitle: {
    fontSize: FONT_SIZE.medium,
    lineHeight: 21,
    fontWeight: FONT_WEIGHT.medium,
    color: "#CFCFCF",
    fontFamily: MAIN_FONT.regular,
  },

  thumbnailLabelInput: {
    fontSize: 12,
    fontWeight: FONT_WEIGHT.medium,
    lineHeight: 18,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.regular,
  },

  thumbnailInput: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#4B555D99",
    backgroundColor: "#19232BD9",
    color: MAIN_COLORS.TEXT_LIGHT,
    fontWeight: FONT_WEIGHT.bold,
    fontSize: FONT_SIZE.medium,
    fontFamily: MAIN_FONT.semi,
  },

  titleDeposit: {
    fontSize: FONT_SIZE.small,
    lineHeight: 18,
    fontWeight: FONT_WEIGHT.bold,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },

  inputDeposit: {
    width: "100%",
    borderWidth: 1,
    borderColor: "#4B555D99",
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 5,
    fontSize: FONT_SIZE.medium,
    fontWeight: FONT_WEIGHT.medium,
    lineHeight: 21,
    color: MAIN_COLORS.PRIMARY_COLOR,
    fontFamily: MAIN_FONT.regular,
  },
});

export default memo(PropertyFormatData);
